import React, { useState, useEffect, useContext } from 'react';
import './Crm.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import hatchback from './CrmImages/hatchback.png';
import house from './CrmImages/house.png';
import switchOn from './CrmImages/switchOn.png';
import decline from './CrmImages/decline.png';
import switchOff from './CrmImages/switchOff.png';
import eye from './CrmImages/eye.png';
import AddCars from "../AddForm/AddCars/AddCars";
import AddFlats from "../AddForm/AddFlats/AddFlats";
import ProductListCar from "./ProductLists/ProductListCar";
import ProductListFlat from "./ProductLists/ProductListFlat";
import stamp from './CrmImages/stamp.png';
import mountain from './CrmImages/mountain.png';
import boat from './CrmImages/boat.png';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import PartnerForm from '../Form/PartnerForm/PartnerForm';

const Crm = () => {
    const { translations } = useContext(LocalizationContext);
    const [activeCategory, setActiveCategory] = useState(null); // 'cars' or 'property'
    const [activeState, setActiveState] = useState('moderation'); // moderation, approved, declined, off
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();
    const [showAddCars, setShowAddCars] = useState(false);
    const [isModalFormOpen, setModalFormOpen] = useState(false);

    const openModal = () => {
        setModalFormOpen(true);
    };

    const closeFormModal = () => {
        setModalFormOpen(false);
    };

    // Fetch the current user's status
    useEffect(() => {
        const fetchUserStatus = async () => {
            if (auth.currentUser) {
                const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setCurrentUser(userDoc.data());
                }
            }
        };
        fetchUserStatus();
    }, []);

    const isAdmin = currentUser?.role === 'admin';

    return (
        <div className="CrmContainer">
            {/* Back Button */}
            <div className="button-back-container">
                <button className="back-button" onClick={() => navigate(-1)}>
                    {translations.supportPage.backSupportButton}
                </button>
            </div>

            <div className={'name-text-Crm'}>
                <h2>{translations.crm.addObjects}</h2>
                <h4>{translations.crm.categoryChoose}</h4>
            </div>

            {/* Category Buttons */}
            <div className="IconFlex">
                <div
                    className={`DisGrid ${activeCategory === 'cars' ? 'active-category' : ''}`}
                    onClick={() => setActiveCategory('cars')}
                >
                    <img className="crmIcon" src={hatchback} alt="Hatchback"/>
                    {translations.crm.cars}
                </div>
                <div
                    className={`DisGrid ${activeCategory === 'property' ? 'active-category' : ''}`}
                    onClick={() => setActiveCategory('property')}
                >
                    <img className="crmIcon" src={house} alt="House"/>
                    {translations.crm.property}
                </div>
                <div
                    className={`DisGrid ${activeCategory === 'yachts' ? 'active-category' : ''}`}
                    onClick={() => {
                        setActiveCategory('yachts');
                        openModal(); // Открытие модального окна при выборе категории
                    }}
                >
                    <img className={'crmIcon'} src={boat} alt="Boat"/>
                    {translations.crm.yachts}
                </div>
                <div
                    className={`DisGrid ${activeCategory === 'tours' ? 'active-category' : ''}`}
                    onClick={() => {
                        setActiveCategory('tours');
                        openModal(); // Открытие модального окна при выборе категории
                    }}
                >
                    <img className={'crmIcon'} src={mountain} alt="Mountain"/>
                    {translations.crm.tours}
                </div>
            </div>

            {/* Add Form for Active Category */}
            <div className="AddFormContainer">
                {activeCategory === 'cars' && <AddCars />}
                {activeCategory === 'property' && <AddFlats />}
                {/*{activeCategory === 'yachts' && <AddYachts />}*/}
                {/*{activeCategory === 'tours' && <AddTours />}*/}
            </div>

            <div className={'name-text-Crm'}>
                <h2>{translations.crm.statustextCRM}</h2>
                <h4>{translations.crm.statustextCRM1}</h4>
            </div>

            {/* State Buttons */}
            <div className="IconFlex">
                {!isAdmin && (
                    <>
                        <div className="DisGrid">
                            <img
                                className={`crmIcon ${activeState === 'approved' ? 'active' : ''}`}
                                src={switchOn}
                                alt="Approved"
                                onClick={() => setActiveState('approved')}
                            />
                            {translations.crm.active}
                        </div>
                        <div className="DisGrid">
                            <img
                                className={`crmIcon ${activeState === 'declined' ? 'active' : ''}`}
                                src={decline}
                                alt="Declined"
                                onClick={() => setActiveState('declined')}
                            />
                            {translations.crm.declined}
                        </div>
                        <div className="DisGrid">
                            <img
                                className={`crmIcon ${activeState === 'off' ? 'active' : ''}`}
                                src={switchOff}
                                alt="SwitchOff"
                                onClick={() => setActiveState('off')}
                            />
                            {translations.crm.off}
                        </div>
                    </>
                )}
                <div className="DisGrid">
                    <img
                        className={`crmIcon ${activeState === 'moderation' ? 'active' : ''}`}
                        src={eye}
                        alt="Moderation"
                        onClick={() => setActiveState('moderation')}
                    />
                    {translations.crm.moderation}
                </div>
            </div>

            {/* Product List for Active Category */}
            <div className="ProductListContainer">
                {activeCategory === 'cars' && (
                    <ProductListCar activeState={activeState} role={currentUser?.role}/>
                )}
                {activeCategory === 'property' && (
                    <ProductListFlat activeState={activeState} role={currentUser?.role}/>
                )}
                {/*{activeCategory === 'yachts' && (*/}
                {/*    <ProductListYachts activeState={activeState} role={currentUser?.role}/>*/}
                {/*)}*/}
                {/*{activeCategory === 'tours' && (*/}
                {/*    <ProductListTours activeState={activeState} role={currentUser?.role}/>*/}
                {/*)}*/}
            </div>

            {/* Модальное окно */}
            {isModalFormOpen && (
                <div className="modal-overlay-form">
                    <div className="modal-content">
                        <PartnerForm closeModal={closeFormModal} />
                        <button className="modal-close-button" onClick={closeFormModal}>❌</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Crm;
