import React, {useState, useEffect, useContext} from 'react';
import './SideFilters.css';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

import sheraton from './CarsLogos/Brands Car/Sheraton.png'
import orbi from './CarsLogos/Brands Car/orbi.png'
import alliance from './CarsLogos/Brands Car/alliance-group.png'
import radissonBlu from './CarsLogos/Brands Car/Radisson Blu.png'
import next from './CarsLogos/Brands Car/Next.png'
import hilton from './CarsLogos/Brands Car/Hilton.png'
import projects from './ProductListCarsImg/projects.svg'




const SideFilters = ({ onFilter }) => {
    const { translations } = useContext(LocalizationContext);
    const [brands, setBrands] = useState([]); // Для хранения выбранных брендов
    const [priceSort, setPriceSort] = useState(''); // Для хранения значения сортировки
    const [dealType, setDealType] = useState([]); // Изначально пустой массив
    const [propertyType, setPropertyType] = useState([]);
    const [company, setCompany] = useState(''); // Для фильтрации по компании
    const [rooms, setRooms] = useState([]); // Состояние для количества комнат

    const [petsAllowed, setPetsAllowed] = useState(false); // Фильтр "можно ли с домашними животными"

    const [showBrands, setShowBrands] = useState(false); // Для отображения или скрытия списка брендов
    const [showFilters, setShowFilters] = useState(false); // Управление видимостью фильтров
    const [showPickupCities, setShowPickupCities] = useState(false); // Управление видимостью селекта для pick-up
    const [pickupCity, setPickupCity] = useState('');

    const allBrands = [
        //
        { name: "Orbi", logo: orbi },
        { name: "Radisson Blu", logo: radissonBlu },
        { name: "Alliance-group", logo: alliance },
        { name: "Next-group", logo: next },
        { name: "Sheraton", logo: sheraton },
        { name: "Hilton", logo: hilton },
    ];

    const city = ["Batumi", "Tbilisi"];

    // Обрабатываем сортировку и другие фильтры
    const handleFilterChange = (newBrands, newPriceSort, newDealType, newCompany, newPropertyType, newPickupCity, newRooms,newPetsAllowed) => {
    onFilter({
        // city: newCity !== undefined ? newCity : city,
        brands: newBrands !== undefined ? newBrands : brands,
        priceSort: newPriceSort !== undefined ? newPriceSort : priceSort,
        dealType: newDealType !== undefined ? newDealType : dealType,
        company: newCompany !== undefined ? newCompany : company,
        propertyType: newPropertyType !== undefined ? newPropertyType : propertyType,
        pickupCity: newPickupCity !== undefined ? newPickupCity : pickupCity,
        rooms: newRooms !== undefined ? newRooms : rooms,
        petsAllowed: newPetsAllowed !== undefined ? newPetsAllowed : petsAllowed,
    });

};

    const CustomDropdown = ({ options, value, onChange, placeholder }) => {
        const [isOpen, setIsOpen] = useState(false);

        const handleSelect = (option) => {
            onChange(option);
            setIsOpen(false); // Закрываем список после выбора
        };
        return (
            <div className="dropdown-container">
                <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                    <div>{value || placeholder}</div>
                    {/* Показываем placeholder, если значение не выбрано */}

                </div>
                {isOpen && (
                    <div className="dropdown-list">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="dropdown-option"
                                onClick={() => handleSelect(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    // Обработка выбора брендов (чекбоксы)
    const handleBrandChange = (brandName) => {
        const updatedBrands = brands.includes(brandName)
            ? brands.filter((brand) => brand !== brandName)
            : [...brands, brandName];

        setBrands(updatedBrands);
        handleFilterChange(updatedBrands, undefined, undefined, undefined);
    };

    const handlePickupCityChange = (city) => {
        setPickupCity(city);
        console.log("pickup city", city);
        handleFilterChange(undefined, undefined, undefined,undefined,undefined,city,undefined,undefined); // Можно добавить логику фильтрации, если нужно
    };



    // Обработка изменений сортировки по цене
    const handlePriceSortChange = (sortType) => {
        setPriceSort(sortType);
        handleFilterChange(undefined, sortType, undefined, undefined);
    };

    // Обработка изменений трансмиссии
    const handleDealTypeChange = (value) => {
        // Проверяем, выбран ли уже этот тип трансмиссии
        const isSelected = dealType.includes(value);

        // Если уже выбран, убираем его из списка, если не выбран — добавляем
        const updatedDealType = isSelected
            ? dealType.filter(item => item !== value) // Убираем
            : [...dealType, value]; // Добавляем

        // Обновляем состояние
        setDealType(updatedDealType);

        // Вызываем handleFilterChange с обновленным массивом трансмиссий
        handleFilterChange(undefined, undefined, updatedDealType, undefined,undefined);
    };

    const handlePropertyTypeChange = (selectedType) => {
    console.log('Current propertyType:', propertyType);
    console.log('Selected type:', selectedType);

    const isSelected = propertyType.includes(selectedType);

    const updatedPropertyType = isSelected
        ? propertyType.filter(item => item !== selectedType)
        : [...propertyType, selectedType];

    console.log('Updated propertyType:', updatedPropertyType);

    setPropertyType(updatedPropertyType);
    handleFilterChange(undefined, undefined, undefined, undefined, updatedPropertyType);
};


    // Обработка изменений компании
    const handleCompanyChange = (e) => {
        const newCompany = e.target.value;
        setCompany(newCompany);
        handleFilterChange(undefined, undefined, undefined, newCompany,undefined);
    };

    // Переключение отображения списка брендов
    const toggleBrandList = () => {
        setShowBrands(!showBrands);
    };

    // Переключение отображения всех фильтров
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    // Переключение отображения селекта городов для pick-up и pick-off
    const togglePickupCities = () => {
        setShowPickupCities(!showPickupCities);
    };
    const handlePetsAllowedChange = () => {
    setPetsAllowed(prevPetsAllowed => {
        const updatedPetsAllowed = !prevPetsAllowed;
        console.log(updatedPetsAllowed);

        // Вызываем фильтрацию с обновленным значением
        handleFilterChange(undefined, // newBrands
            undefined, // newPriceSort
            undefined, // newDealType
            undefined, // newCompany
            undefined, // newPropertyType
            undefined, // newPickupCity
            undefined, // newRooms
            updatedPetsAllowed // newPetsAllowed
        );

        return updatedPetsAllowed; // Обновляем состояние
    });
};


   const handleRoomsChange = (selectedRoom) => {
    setRooms(prevRooms => {
        const isSelected = prevRooms.includes(selectedRoom);
        const updatedRoomsFilter = isSelected
            ? prevRooms.filter(room => room !== selectedRoom) // Убираем, если уже выбрано
            : [...prevRooms, selectedRoom]; // Добавляем, если не выбрано

        // Вызываем фильтрацию с обновленным значением
        handleFilterChange(undefined, undefined, undefined, undefined, undefined,undefined ,updatedRoomsFilter);

        return updatedRoomsFilter; // Обновляем состояние
    });
};



    return (
        <div className="filter-buttons">
            <div className="FilterWrap">
                <div className={'disFlex BrandWrapper'}>
                    <CustomDropdown
                        options={city}
                        value={pickupCity}
                        onChange={handlePickupCityChange}
                        placeholder={(
                            <div className="placeholder-content">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="5" y="20" width="10" height="25" fill="currentColor"/>
                                    <rect x="20" y="10" width="10" height="35" fill="currentColor"/>
                                    <rect x="35" y="15" width="10" height="30" fill="currentColor"/>
                                    <rect x="8" y="25" width="3" height="5" fill="white"/>
                                    <rect x="8" y="33" width="3" height="5" fill="white"/>
                                    <rect x="23" y="15" width="3" height="5" fill="white"/>
                                    <rect x="23" y="23" width="3" height="5" fill="white"/>
                                    <rect x="23" y="30" width="3" height="5" fill="white"/>
                                    <rect x="38" y="20" width="3" height="5" fill="white"/>
                                    <rect x="38" y="28" width="3" height="5" fill="white"/>
                                    <rect x="38" y="35" width="3" height="5" fill="white"/>
                                </svg>


                                <div className={'placeholder-info'}>
                                    <div className={'maintext'}>{translations.productListFlats.city}</div>
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div className={'disFlex BrandWrapper priceFilters'}>
                    <div className={'pricefilters'}><p>{translations.productListFlats.price}:</p>
                        <div className={'disFlex centring'}><label
                            className={`priceLabel ${priceSort === 'high' ? 'active' : ''}`}
                        >
                            <input
                                type="checkbox"
                                checked={priceSort === 'high'}
                                onChange={() => handlePriceSortChange('high')}
                                className="hiddenCheckbox"
                            />
                            {translations.productListFlats.high}
                        </label>

                            <label
                                className={`priceLabel ${priceSort === 'low' ? 'active' : ''}`}
                            >
                                <input
                                    type="checkbox"
                                    checked={priceSort === 'low'}
                                    onChange={() => handlePriceSortChange('low')}
                                    className="hiddenCheckbox"
                                />
                                {translations.productListFlats.low}
                            </label></div>
                    </div>
                </div>
                <div className={'disFlex BrandWrapper'} onClick={toggleBrandList} style={{cursor: 'pointer'}}>


                    <svg className="FilterIcon" width="26" height="26" viewBox="0 0 26 26" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 2H22C23.1046 2 24 2.89543 24 4V24C24 25.1046 23.1046 26 22 26H4C2.89543 26 2 25.1046 2 24V4C2 2.89543 2.89543 2 4 2ZM4 4V24H22V4H4Z"
                            fill="currentColor"/>
                        <path
                            d="M6 6H10V10H6V6ZM6 12H10V16H6V12ZM6 18H10V22H6V18ZM12 6H20V10H12V6ZM12 12H20V16H12V12ZM12 18H20V22H12V18Z"
                            fill="currentColor"/>
                    </svg>


                    <div className={'brnd'}>{translations.productListFlats.projects}</div>

                </div>
                <div className="toggle-filters-button" onClick={toggleFilters}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.08332 5.14663H4.04732C4.63232 7.29884 6.85142 8.56936 9.00384 7.98442C10.3863 7.60872 11.4662 6.52891 11.8419 5.14663H24.9167C25.515 5.14663 26 4.66167 26 4.06342C26 3.46518 25.515 2.98021 24.9167 2.98021H11.8419C11.2569 0.827957 9.03781 -0.442567 6.88539 0.142376C5.50297 0.51807 4.42305 1.59788 4.04732 2.98016H1.08332C0.485013 2.98016 0 3.46513 0 4.06337C0 4.66162 0.485013 5.14663 1.08332 5.14663Z"
                            fill="currentColor"/>
                        <path
                            d="M24.9167 11.9168H21.9527C21.3688 9.76488 19.151 8.49364 16.9989 9.07736C15.6153 9.45265 14.5345 10.5333 14.1592 11.9168H1.08332C0.485013 11.9168 0 12.4017 0 13C0 13.5982 0.485013 14.0832 1.08332 14.0832H14.1592C14.743 16.2351 16.9609 17.5063 19.113 16.9226C20.4965 16.5473 21.5773 15.4666 21.9527 14.0832H24.9167C25.515 14.0832 26 13.5982 26 13C26 12.4017 25.515 11.9168 24.9167 11.9168Z"
                            fill="currentColor"/>
                        <path
                            d="M24.9167 20.8532H11.8419C11.2569 18.701 9.03781 17.4305 6.88539 18.0154C5.50297 18.3911 4.42305 19.4709 4.04732 20.8532H1.08332C0.485013 20.8532 0 21.3382 0 21.9364C0 22.5347 0.485013 23.0196 1.08332 23.0196H4.04732C4.63232 25.1718 6.85142 26.4424 9.00384 25.8574C10.3863 25.4817 11.4662 24.4019 11.8419 23.0196H24.9167C25.515 23.0196 26 22.5347 26 21.9364C26 21.3382 25.515 20.8532 24.9167 20.8532Z"
                            fill="currentColor"/>
                    </svg>
                    <div>{translations.productListFlats.filters}</div>
                </div>
            </div>
            {showBrands && (
                <div className="brand-list">
                    {allBrands.map((brand) => (
                        <div key={brand.name} className="brand-item" onClick={() => handleBrandChange(brand.name)}>
                            <img
                                src={brand.logo}
                                alt={brand.name}
                                className={`brand-logo ${brands.includes(brand.name) ? 'selected' : ''}`}
                            />
                            <span>{brand.name}</span>
                        </div>
                    ))}
                </div>
            )}
            {showFilters && (
                <div className="filters-container">
                    <div className="filter-item">
                        <label>{translations.productListFlats.partners}</label>
                        <input
                            className={'partner-input'}
                            type="text"
                            placeholder={translations.productListFlats.enterCompanyName}
                            value={company}
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div className="filter-item">
                        <label>{translations.productListFlats.dealType}</label>
                        <div className="child">

                            <div className={''}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={dealType.includes('аренда')}
                                        onChange={() => handleDealTypeChange('аренда')}
                                    />
                                    🔑 {translations.productListFlats.rent}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={dealType.includes('продажа')}
                                        onChange={() => handleDealTypeChange('продажа')}
                                    />
                                    💰 {translations.productListFlats.sale}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={dealType.includes('посуточно')}
                                        onChange={() => handleDealTypeChange('посуточно')}
                                    />
                                   🕒 {translations.productListFlats.daily}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="filter-item">
                        <label>{translations.productListFlats.propertyType}</label>
                        <div className={'child'}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Квартира')}
                                    onChange={() => handlePropertyTypeChange('Квартира')}
                                />
                                🏢 {translations.productListFlats.flat}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Частный дом')}
                                    onChange={() => handlePropertyTypeChange('Частный дом')}
                                />
                               🏡 {translations.productListFlats.privateHouse}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Вилла')}
                                    onChange={() => handlePropertyTypeChange('Вилла')}
                                />
                                🏝️ {translations.productListFlats.villa}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Отель')}
                                    onChange={() => handlePropertyTypeChange('Отель')}
                                />
                                🏨 {translations.productListFlats.hotel}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Коммерческое помещение')}
                                    onChange={() => handlePropertyTypeChange('Коммерческое помещение')}
                                />
                                🏢 {translations.productListFlats.commercialSpace}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Участок')}
                                    onChange={() => handlePropertyTypeChange('Участок')}
                                />
                                🌳 {translations.productListFlats.plot}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={propertyType.includes('Коворкинг')}
                                    onChange={() => handlePropertyTypeChange('Коворкинг')}
                                />
                               💼 {translations.productListFlats.coworking}
                            </label>
                        </div>
                    </div>
                    <div className="filter-item">
                        <label>{translations.productListFlats.numberOfRooms}</label>
                        <div className="child">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rooms.includes('1')} // Проверяем, выбрана ли 1 комната
                                    onChange={() => handleRoomsChange('1')} // Обновляем состояние при клике
                                />
                                1️⃣ {translations.productListFlats.room}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rooms.includes('2')} // Проверяем, выбраны ли 2 комнаты
                                    onChange={() => handleRoomsChange('2')}
                                />
                                2️⃣ {translations.productListFlats.rooms}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rooms.includes('3')}
                                    onChange={() => handleRoomsChange('3')}
                                />
                                3️⃣ {translations.productListFlats.rooms}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rooms.includes('4')}
                                    onChange={() => handleRoomsChange('4')}
                                />
                                4️⃣ {translations.productListFlats.rooms}
                            </label>
                        </div>
                        <div className="filter-item">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={petsAllowed} // Проверяем текущее состояние
                                    onChange={handlePetsAllowedChange} // Обновляем состояние при клике
                                />
                                {translations.productListFlats.pets}🐶🐱
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideFilters;
