import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './CategoryBar.css';
import CategoryPopup from '../../CategoryPopup/CategoryPopup';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';
import {CategoryContext} from "../../../App";

const CategoryBar = ({ isMapView }) => {
    const { translations } = useContext(LocalizationContext);


    const categories = [
        { id: 'cars', icon: 'fas fa-car', label: translations.categories.car, route: '/product-list-cars' },
        { id: 'flats', icon: 'fas fa-building', label: translations.categories.flat, route: '/product-list-flats' },
    ];

    const navigate = useNavigate();

    // States
    const [showAll, setShowAll] = useState(true);
    const [isBarHidden, setIsBarHidden] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [activeCategory, setActiveCategory] = useState(CategoryContext);  // Track active category

    const categoryContainerRef = useRef(null);

    const handleCategoryClick = (route, id) => {
        navigate(route);

        setActiveCategory(id);  // Set the active category when clicked

    };
    useEffect(() => {
        if (location.pathname === '/product-list-cars') {
            setActiveCategory('cars');
        } else if (location.pathname === '/product-list-flats') {
            setActiveCategory('flats');
        }
    }, [location.pathname]);

    // Handle scroll
    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        if (currentScrollPos > scrollPos && currentScrollPos > 100) {
            setIsBarHidden(true);
        } else {
            setIsBarHidden(false);
        }
        setScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPos]);

    const toggleShowAll = () => setShowAll((prev) => !prev);

    const renderCategoryItems = () => (
        <div className="category-items-container" ref={categoryContainerRef}>
            {categories.map(({ id, icon, label, route }) => (
                <div key={id} className={`category-bar__item ${activeCategory === id ? 'active' : ''}`}>
                    <button
                        onClick={() => handleCategoryClick(route, id)}
                        className="category-button"
                    >
                        <i className={icon}></i>
                        <span className="category-name">{label}</span>
                    </button>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {isMapView && (
                <div className="toggle-button-container">
                    <button onClick={toggleShowAll} className="toggle-category-button">
                        <i className={`fas ${showAll ? 'fa-times' : 'fa-sliders-h'}`}></i>
                    </button>
                </div>
            )}
            <div
                className={`category-bar ${
                    isBarHidden ? 'hidden' : ''
                } ${isMapView ? 'map-view' : 'product-list'}`}
            >
                {!isMapView ? renderCategoryItems() : showAll && renderCategoryItems()}
            </div>

            <CategoryPopup
                isOpen={showPopup}
                message={popupMessage}
                onClose={() => setShowPopup(false)}
            />
        </>
    );
};

export default CategoryBar;
