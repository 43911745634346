import React, { useState, useEffect, useRef, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './AddMap.css';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2VlZWZhbWlseSIsImEiOiJjbTIzZ2tmdzAwNHJrMmpzajIzZnM3dXAyIn0.-jlvqtAePL_sL9P015ElTg';

const AddMap = ({ onMapDataChange }) => {
    const { translations } = useContext(LocalizationContext);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markerRef = useRef(null);
    const [lng, setLng] = useState(41.62417327107303);
    const [lat, setLat] = useState(41.59507866058823);
    const [isMapVisible, setIsMapVisible] = useState(false);

    useEffect(() => {
        if (!isMapVisible) {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
            return;
        }

        if (map.current || !mapContainer.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: 9,
        });

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
        });

        map.current.addControl(geocoder, 'top-left');

        const geolocateControl = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserHeading: true,
        });

        map.current.addControl(geolocateControl, 'top-left');

        markerRef.current = new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .addTo(map.current);

        map.current.on('click', (e) => {
            const coordinates = e.lngLat;

            if (markerRef.current) {
                markerRef.current.remove();
            }

            setLng(coordinates.lng);
            setLat(coordinates.lat);

            markerRef.current = new mapboxgl.Marker()
                .setLngLat([coordinates.lng, coordinates.lat])
                .addTo(map.current);

            localStorage.setItem('mapCoordinates', JSON.stringify({ lng: coordinates.lng, lat: coordinates.lat }));
            if (onMapDataChange) onMapDataChange({ lng: coordinates.lng, lat: coordinates.lat });
        });

        map.current.on('move', () => {
            const { lng, lat } = map.current.getCenter();
            setLng(lng);
            setLat(lat);
            if (markerRef.current) {
                markerRef.current.setLngLat([lng, lat]);
            }
        });

        window.addEventListener('resize', () => {
            if (map.current) {
                map.current.resize();
            }
        });

        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
            window.removeEventListener('resize', () => {
                if (map.current) {
                    map.current.resize();
                }
            });
        };
    }, [isMapVisible]);

    const handleSaveLocation = () => {
        localStorage.setItem('mapCoordinates', JSON.stringify({ lng, lat }));
        if (onMapDataChange) onMapDataChange({ lng, lat });

        setIsMapVisible(false);
    };

    return (
        <div className="MapWrapper" style={{ width: '100%' }}>
            <button
                className="ToggleMapButton"
                type="button"
                onClick={() => setIsMapVisible(!isMapVisible)}
                style={{ width: '100%' }}
            >
                {isMapVisible ? translations.addMap.collapseMap : translations.addMap.placeObjectOnMap}
            </button>
            {isMapVisible && (
                <div className="MapContainer">
                    <div className="AddMap" ref={mapContainer}/>
                    <button className="CloseMapButton" onClick={() => setIsMapVisible(false)}>X</button>
                    <button className="SaveLocationButton" onClick={handleSaveLocation}>{translations.addMap.saveLocation}</button>
                </div>
            )}
        </div>
    );
};

export default AddMap;
