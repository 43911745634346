import React, { useState, useContext } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DatePicker.css';
import CarForm from '../../Form/CarForm/CarForm';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const DatePicker = ({ onDatesChange, startDate, endDate, onDaysCountChange,  price1day, price2to3days, price4to7days, price8to15days, price16to30days, price31plusdays, objectID, carName, carModel,carCompany,priceForSelling}) => {
    const { translations } = useContext(LocalizationContext);
    const today = new Date();
    const [selectedRange, setSelectedRange] = useState({
        startDate: startDate || today,
        endDate: endDate || today,
        key: 'selection'
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormVisible, setFormVisible] = useState(true);

    const handleClose = () => {
        setFormVisible(false);
    };

    const handleSelect = (ranges) => {
        const { selection } = ranges;
        setSelectedRange(selection);
        if (onDatesChange) {
            onDatesChange(selection.startDate, selection.endDate);
        }
        if (onDaysCountChange) {
            onDaysCountChange(getDaysCount());
        }
    };

    const getDaysCount = () => {
        const { startDate, endDate } = selectedRange;
        if (startDate && endDate) {
            const diffTime = Math.abs(endDate - startDate);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        }
        return 0;
    };
    const daysCount = getDaysCount();

    let pricePerDay = price1day;

    if (daysCount >= 2 && daysCount <= 3) {
        pricePerDay = price2to3days;
    } else if (daysCount >= 4 && daysCount <= 7) {
        pricePerDay = price4to7days;
    } else if (daysCount >= 8 && daysCount <= 15) {
        pricePerDay = price8to15days;
    } else if (daysCount >= 16 && daysCount <= 30) {
        pricePerDay = price16to30days;
    } else if (daysCount > 30) {
        pricePerDay = price31plusdays;
    }

    const totalPrice = daysCount * pricePerDay;

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="datepicker-container">
            <div className="datepicker">
                <DateRangePicker
                    ranges={[selectedRange]}
                    onChange={handleSelect}
                    minDate={today}
                />
            </div>
            <div className="total-price">
    <span className="price-value">
        {pricePerDay} <span className="per-day">{translations.datePicker.perDay}</span>
    </span>
                <div className="price-value">
                    {daysCount} {daysCount === 1 ? translations.datePicker.day : translations.datePicker.daysPlural}
                </div>
                <div className="price-value">
                    {translations.datePicker.price}: {totalPrice} $
                </div>
            </div>

            <div className="disFlex BookMsgBtn">
                <button className="button l_btn" onClick={toggleModal}>
                    {translations.datePicker.book}
                </button>
                <button className="button r_btn" onClick={() => window.open('https://t.me/weeekendteam', '_blank')}>
                    {translations.datePicker.chat}
                </button>
            </div>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close" onClick={toggleModal}>✖</button>
                        {isFormVisible && (
                            <CarForm
                                onClose={handleClose}
                                objectId={objectID}
                                carName={carName}
                                carModel={carModel}
                                carCompany ={carCompany}
                                daysCount={getDaysCount()}
                                price={price1day}
                            />
                        )}
                        {!isFormVisible && <p>{translations.datePicker.formClosed}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePicker;
