import './App.css';
import React, {useEffect, useState} from "react";
import {useTelegram} from "./Components/hooks/useTelegram";
import {Route,Routes} from "react-router-dom";
import Header from "./Components/header/header";
// import ProductList from "./Components/ProductList/ProductList";
import CarForm from "./Components/Form/CarForm/CarForm";
import Profile from "./Components/Profile/Profile";
import NavBar from "./Components/NavBar/NavBar";
import Map from "./Components/Map/map";
import UnavailableCategoryPage from "./Components/UnavailableCategoryPage/UnavailableCategoryPage";
import PartnerForm from "./Components/Form/PartnerForm/PartnerForm";
import LoginForm from "./Components/LoginForm/LoginForm";
import {LocalizationProvider} from "./Services/localization/LocalizationContext";
import SignUpForm from "./Components/SignUpForm/SignUpForm";
import ModerationPage from './Components/ModerationPage/ModerationPage';
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import "flag-icons/css/flag-icons.min.css";
import {UserProvider} from "./Services/context/UserContext";
import ProductListCars from "./Components/ProductListCars/ProductListCars";
import ProductListFlats from "./Components/ProductListFlat/ProductListFlats";
import addCars from "./Components/AddForm/AddCars/AddCars";
import AddCars from "./Components/AddForm/AddCars/AddCars";
import Crm from "./Components/CRM/Crm";
import Home from "../src/Components/Home/Home"
import AppLoader from "./Components/Loader/AppLoader";
import SupportForm from '../src/Components/Profile/SupportForm/SupportForm';


// import {
//     ProductList,
//     CarForm,
//     Profile,
//     NavBar,
//     Map,
//     Home,
//     UnavailableCategoryPage,
//     PartnerForm,
//     LoginForm,
//     SignUpForm,
//     ModerationPage
// } from './Services/LazyLoading/LazyLoading';


export const CategoryContext = React.createContext();

function App() {
    const {onToggleButton, tg} = useTelegram();
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeCategories, setActiveCategories] = useState(() => {
        const storedCategories = sessionStorage.getItem('activeCategories');
        return storedCategories ? JSON.parse(storedCategories) : [];
    });

    useEffect(() => {
        tg.ready();
    },[])

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        sessionStorage.setItem('activeCategories', JSON.stringify(activeCategories));
    }, [activeCategories]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Задержка на 5 секунд перед отображением приложения
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timer); // Чистим таймер при размонтировании
    }, []);









    if (isLoading) {
        return  <AppLoader />
    }


        return (

            <UserProvider>
                <LocalizationProvider>

                    <CategoryContext.Provider value={{ activeCategories, setActiveCategories }}>
                        <div className="App">
                            <Header />
                            <Routes>
                                <Route index element={<Home/>} />
                                <Route path="/product-list-cars" element={<ProductListCars/>}/>
                                <Route path="/product-list-flats" element={<ProductListFlats/>}/>
                                <Route path={'form'} element={<CarForm />} />
                                <Route path="/home" element={<Home />} />
                                <Route path="profile" element={currentUser ? <Profile /> : <LoginForm />} />
                                <Route path={'map'} element={<Map />} />
                                <Route path={'/unavailable-category'} element={<UnavailableCategoryPage />} />
                                <Route path={'/become-partner'} element={<PartnerForm />} />
                                <Route path="/login" element={currentUser ? <Profile /> : <LoginForm />} />
                                <Route path="/signup" element={currentUser ? <Profile /> : <SignUpForm />} />
                                <Route path="/moderation" element={<ModerationPage />} />
                                <Route path="/CRM" element={<Crm/>} />
                                <Route path="/support" element={<SupportForm />} />
                            </Routes>
                            <NavBar />
                        </div>
                    </CategoryContext.Provider>
                </LocalizationProvider>
            </UserProvider>
        );
}

export default App;