import React, { useState, useContext } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { storage } from '../../firebase';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const ChangeImage = ({ carId, currentImageUrl, onImageUrlChange }) => {
    const { translations } = useContext(LocalizationContext);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(currentImageUrl);

    // Функция для загрузки файла в Firebase Storage
    const uploadFile = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Прогресс загрузки (дополнительно можно добавить индикатор загрузки)
                },
                (error) => {
                    console.error('Ошибка загрузки файла: ', error);
                    reject(error);
                },
                () => {
                    // Получаем URL после успешной загрузки
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    }).catch(reject);
                }
            );
        });
    };

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) return;

        setLoading(true);

        try {
            const downloadURL = await uploadFile(file); // Загружаем файл и получаем URL
            setImageUrl(downloadURL); // Обновляем локально

            // Обновляем Firestore с новым URL
            const firestore = getFirestore();
            const carDocRef = doc(firestore, 'cars', carId);
            await updateDoc(carDocRef, {
                imageUrl: downloadURL, // Обновляем поле imageUrl в документе
            });

            // Передаем новый URL родительскому компоненту
            if (onImageUrlChange) {
                onImageUrlChange(downloadURL);
            }

        } catch (error) {
            console.error('Ошибка при загрузке изображения: ', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="image-upload">
            <label htmlFor="file-input">{translations.changeImage.uploadPrompt}</label>
            <input id="file-input" type="file" onChange={handleFileChange}/>
            <button onClick={handleUpload} disabled={loading}>
                {loading ? translations.changeImage.loading : translations.changeImage.uploadButton}
            </button>
            {imageUrl && (
                <div className="image-preview">
                    <img src={imageUrl} alt={translations.changeImage.previewAltText}/>
                    <span>{translations.changeImage.previewLabel}</span>
                </div>
            )}
        </div>

    );
};

export default ChangeImage;
