import React, { useState, useContext } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DatePicker.css';
import FlatForm from '../../Form/FlatForm/FlatForm';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const DatePickerFlat = ({
  onDatesChange,
  startDate,
  endDate,
  onDaysCountChange,
  price1day,
  price2to3days,
  price4to7days,
  price8to15days,
  priceForSelling,
  objectID,
  carName,
  carCity,
  carCompany,
  propertyType,
  carAddress,
}) => {
  const { translations } = useContext(LocalizationContext);

  const today = new Date();
  const [selectedRange, setSelectedRange] = useState({
    startDate: startDate || today,
    endDate: endDate || today,
    key: 'selection',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormVisible, setFormVisible] = useState(true);

  const handleClose = () => {
    setFormVisible(false);
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectedRange(selection);
    if (onDatesChange) {
      onDatesChange(selection.startDate, selection.endDate);
    }
    if (onDaysCountChange) {
      onDaysCountChange(getDaysCount());
    }
  };

  const getDayEnding = (days) => {
    if (days % 10 === 1 && days % 100 !== 11) {
      return 'день';
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
      return 'дня';
    } else {
      return 'дней';
    }
  };

  const getDaysCount = () => {
    const { startDate, endDate } = selectedRange;
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    }
    return 0;
  };

  const daysCount = getDaysCount();

  let pricePerDay = price1day;

  if (daysCount === 3) {
    pricePerDay = price2to3days;
  } else if (daysCount === 7) {
    pricePerDay = price4to7days;
  } else if (daysCount === 14) {
    pricePerDay = price8to15days;
  } else if (daysCount === 30) {
    pricePerDay = price8to15days;
  }

  const totalPrice = daysCount * pricePerDay;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="datepicker-container">
      <div className="datepicker">
        <DateRangePicker
          ranges={[selectedRange]}
          onChange={handleSelect}
          minDate={today}
        />
      </div>

      <div className="total-price">
        {priceForSelling ? (
          // Отображение только для продажи
            <div className="price-value">
              {translations.datePicker.price}: {priceForSelling} $
            </div>

        ) : (
            // Отображение для аренды
          <>
            <span className="price-value">
              {pricePerDay} <span className="per-day">{translations.datePicker.perDay}</span>
            </span>
            <div className="price-value">
              {daysCount} {getDayEnding(daysCount)}
            </div>
            <div className="price-value">
              {translations.datePicker.price}: {totalPrice} $
            </div>
          </>
        )}
      </div>

      <div className="disFlex BookMsgBtn">
        <button className="button l_btnFlat" onClick={toggleModal}>
          {translations.productListCars.liveView}
        </button>
        <button
          className="button r_btn"
          onClick={() => window.open('https://t.me/weeekendteam', '_blank')}
        >
          {translations.datePicker.chat}
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close" onClick={toggleModal}>
              ✖
            </button>
            {isFormVisible && (
              <FlatForm
                onClose={handleClose}
                objectId={objectID}
                propertyType={propertyType}
                carName={carName}
                carCity={carCity}
                carCompany={carCompany}
                daysCount={getDaysCount()}
                price={price1day}
                carAddress={carAddress}
                ranges={selectedRange}
              />
            )}
            {!isFormVisible && <p>{translations.datePicker.formClosed}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerFlat;
