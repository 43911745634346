import React, { useEffect, useState, useContext  } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { FaBluetoothB, FaHeadphones, FaSnowflake, FaUsb } from 'react-icons/fa';
import DatePickerFlat from "./DatePicker/DatePickerFlat";
import './DatePicker/DatePicker.css';
import './ProductListFlats.css';
import './CategoryBanner.css'
import { PiEngine } from "react-icons/pi";
import { IconManualGearbox } from '@tabler/icons-react';
import Slider from 'react-slick'; // Импортируем слайдер
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CategoryBar from "../CategoryBar/CategoryBarCategories/CategoryBar";
// Импортируем компонент фильтров
import SideFilters from './SideFilters'; // Импортируем компонент фильтров
import floor from './ProductListCarsImg/floor.svg'
import bedroom from './ProductListCarsImg/bedrooms.svg'
import square from './ProductListCarsImg/square.svg'
import elevator from './ProductListCarsImg/elevator.svg'
import rooms from './ProductListCarsImg/rooms.svg'
import bathrooms from './ProductListCarsImg/bathrooms.svg'
import heating from './ProductListCarsImg/heating.svg'
import pets from './ProductListCarsImg/pets.svg'
import gas from './ProductListCarsImg/gas.svg'
import view from './ProductListCarsImg/view.svg'
import calendarsvg from './ProductListCarsImg/calendar-days (1) 1.svg'
import categoryBanner from '../img/flatCategory.jpg';
import {CategoryContext} from "../../App";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import { useNavigate } from 'react-router-dom';


const ProductListFlats = () => {
    const { translations } = useContext(LocalizationContext);
    const [cars, setCars] = useState([]);
    const [openCarId, setOpenCarId] = useState(null); // Хранит ID машины, у которой открыт календарь
    const [filteredProducts, setFilteredProducts] = useState([]); // Фильтрованные автомобили
    const { activeCategories, setActiveCategories } = useContext(CategoryContext);
    const [products, setProducts] = useState([]); // Исходные данные
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const [pageNumbers, setPageNumbers] = useState([]);
    // Индексы для отображаемых элементов
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCars = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
        const navigate = useNavigate();

    useEffect(() => {
        const fetchCars = async () => {
            try {
                const carsCollection = collection(db, 'flats');
                const carSnapshot = await getDocs(carsCollection);
                const carList = carSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(car => car.status === 'approved'); // Фильтруем по статусу
                setCars(carList);
                setFilteredProducts(carList); // Инициализируем фильтрованные машины
            } catch (error) {
                console.error("Error fetching cars: ", error);
            }
        };
        fetchCars();
    }, []);
    const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
    // Проверяем, является ли feature булевым значением или числом
    const isActive = feature === true; // Активно, если feature = true (для булевого значения)

    // Стиль для активной иконки (синяя подсветка)
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' }; // Синяя подсветка

    // Стиль для неактивной иконки
    const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' }; // Неактивный цвет

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={isActive ? activeStyle : inactiveStyle}  // Применяем активный или неактивный стиль
                alt={altText}
            />
            <div className="bluetooth-label">{featureLabel}</div>
        </div>

    );
};
    const renderFeatureIconUp = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={activeStyle}
                alt={altText}
            />
            <div className="bluetooth-label">{featureLabel}</div>
        </div>

    );
};


    useEffect(() => {
        const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
        setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
        setCurrentPage(1); // Сбрасываем на первую страницу после фильтрации
    }, [filteredProducts]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
          // Прокрутка страницы вверх

        const cardsContainer = document.querySelector('.category-banner');
        if (cardsContainer) {
        cardsContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Прокрутить так, чтобы верх карточек был виден
        });
     }
    };

    // Обработка фильтров
    const handleFilter = (filters) => {
        const { brands, priceSort, dealType, company, propertyType ,rooms,city,petsAllowed} = filters;
        console.log("Received filters:", filters); // Проверка всех переданных фильтров, включая city

        let filtered = cars.filter(car => {
            const matchesBrand = brands.length > 0 ? brands.includes(car.company) : true;
            const matchesDealType = dealType.length > 0
                ? car.dealType && dealType.some(deal => car.dealType.toLowerCase() === deal.toLowerCase())
                : true;
            const matchesCompany = company ? car.company && car.company.toLowerCase().includes(company.toLowerCase()) : true;
            const matchesPropertyType = propertyType.length > 0
                ? car.propertyType && propertyType.some(propertyType => car.propertyType.toLowerCase() === propertyType.toLowerCase())
                : true;

            // Проверка соответствия города
            const matchesCity = filters.pickupCity ? car.city && car.city.toLowerCase().trim() === filters.pickupCity.toLowerCase().trim() : true;

            const matchesRooms = rooms.length > 0 ? rooms.includes(car.rooms) && car.rooms !== undefined && car.rooms !== null : true;

            const matchesPetsAllowed = petsAllowed ? car.petsAllowed === true : true;

console.log("Received car city:", car.city);
            // Логи для отслеживания значений


            return matchesBrand && matchesDealType && matchesCompany && matchesPropertyType &&  matchesRooms && matchesCity&& matchesPetsAllowed;
        });

            if (priceSort === 'high') {
                filtered = filtered.sort((a, b) => b.pricePerDay - a.pricePerDay);
            } else if (priceSort === 'low') {
                filtered = filtered.sort((a, b) => a.pricePerDay - b.pricePerDay);
            }
        setFilteredProducts(filtered);
        setCurrentPage(1); // Сбрасываем на первую страницу после изменения фильтров
    };

    const handleCategoryChange = (selectedCategories) => {
        if (JSON.stringify(selectedCategories) !== JSON.stringify(activeCategories)) {
            setActiveCategories(selectedCategories);

            // Если категория "cars" выбрана, показываем все автомобили
            if (selectedCategories.includes('cars')) {
                setFilteredProducts(cars); // Показать весь список автомобилей
            } else {
                // В противном случае фильтруем по категории
                const filtered = cars.filter(car => selectedCategories.includes(car.category));
                setFilteredProducts(filtered);
            }
            setCurrentPage(1); // Сбрасываем на первую страницу
        }
    };
    const toggleDatePicker = (carId) => {
        setOpenCarId(prevId => (prevId === carId ? null : carId)); // Если уже открыт, закрыть, иначе открыть
    };

    // Настройки для слайдера
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const handleAddressClick = async (id) => {
        // Пример с передачей координат напрямую

        const route = `/map`;
        localStorage.setItem("currentPopupId", id);
        navigate(route);
    };
    return (
        <div>
            <div className="category-banner" style={{backgroundImage: `url(${categoryBanner})`}}>
                <div className="banner-content-wrapper">
                    <div className="banner-title-section">
                        <div className="title-text">
                            <h1 className="main-title">Weeekend</h1>
                            <h2 className="beta-tag">BETA 1.1</h2>
                        </div>
                        <p className="banner-description">
                            We are a one-stop platform for booking travel, renting accommodations, cars, water
                            activities, and more.
                            In one place, you can easily find the best offers for car rentals, homes, yachts, jet
                            skis,
                            and book exciting activities like parasailing!
                        </p>
                    </div>
                    <div className="trademark-sign">®</div>
                </div>
            </div>
            <CategoryBar onCategoryChange={handleCategoryChange} isMapView={false}/>


            <div>
                <div className={'side-filters'}><SideFilters onFilter={handleFilter}/></div>
                {/* Добавляем фильтры */}
                <div className="car-list">
                        {currentCars.map((car) => (
                            <div key={car.id} className="car-card">
                                <div className="company-name-overlay">
                                    <p>{car.company}</p>
                                </div>
                                {/* Проверяем наличие imageUrls, если они есть, используем слайдер */}
                                {car.imageUrls && car.imageUrls.length > 0 ? (
                                    <Slider {...sliderSettings}>
                                        {/* Главное изображение */}
                                        <div>
                                            <img src={car.imageUrl} alt={`${car.city} ${car.description}`}
                                                 className="car-image"
                                                 loading={"lazy"}/>
                                        </div>
                                        {/* Дополнительные изображения */}
                                        {car.imageUrls.map((url, index) => (
                                            <div key={index}>
                                                <img src={url} alt={`${car.city} image ${index}`} className="car-image"
                                                     loading={"lazy"}/>
                                            </div>
                                        ))}
                                    </Slider>
                                ) : (
                                    <img src={car.imageUrl} alt={`${car.city} ${car.description}`}
                                         className="car-image"/>
                                )}
                                <div className="car-details">
                                    <div className="LeftRight">
                                        <div className={'disFlex brandvolume'}>
                                            <h3 className="right">
                                                {car.propertyType} in {car.city}
                                            </h3>
                                            <p className="wd">
                                                {car.area}
                                            </p>
                                        </div>
                                        <p>{car.dealType}</p></div>
                                    <div className="description">
                                        <p onClick={() => handleAddressClick(car.id)}
                                           style={{cursor: "pointer", color: "blue"}}>
                                            {car.address}
                                        </p>
                                    </div>
                                    <div className="car-details">
                                        <div className="disFlex elementsIcons IconGrid">
                                            {renderFeatureIconUp(car.rooms, `${translations.productListFlats.Rooms} ${car.rooms}`, rooms, "Rooms", openCarId, car.id)}
                                            {renderFeatureIconUp(car.floor, `${translations.productListFlats.floor} ${car.floor}`, floor, "Floor", openCarId, car.id)}
                                            {renderFeatureIconUp(car.bedrooms, `${translations.productListFlats.bedrooms} ${car.bedrooms}`, bedroom, "Bedrooms", openCarId, car.id)}
                                            {renderFeatureIconUp(car.bathrooms, `${translations.productListFlats.bathrooms} ${car.bathrooms}`, bathrooms, "Bathrooms", openCarId, car.id)}
                                            {renderFeatureIconUp(car.view, ` ${car.view}`, view, "Bathrooms", openCarId, car.id)}
                                        </div>
                                    </div>


                                    {openCarId === car.id && (

                                        <>
                                            <div className="parameters-container">
                                                {renderFeatureIcon(car.hasElevator, `${translations.productListFlats.elevator}`, elevator, "Elevator", openCarId, car.id)}
                                                {renderFeatureIcon(car.hasGas, `${translations.productListFlats.gas}`, gas, "Gas", openCarId, car.id)}
                                                {renderFeatureIcon(car.hasHeating, `${translations.productListFlats.heating}`, heating, "Heating", openCarId, car.id)}
                                                {renderFeatureIcon(car.petsAllowed, `${translations.productListFlats.pets}`, pets, "Pets", openCarId, car.id)}
                                            </div>
                                            <DatePickerFlat
                                                price1day={car.pricePerDay}
                                                price2to3days={car.pricePer3Days}
                                                price4to7days={car.pricePerWeek}
                                                price8to15days={car.pricePer14Days}
                                                price15to30days={car.pricePerMonth}
                                                objectID={car.id}
                                                carCompany={car.company}
                                                carCity={car.city}
                                                propertyType={car.propertyType}
                                                carAddress={car.address}
                                                priceForSelling={car.priceForSelling}
                                            />
                                        </>
                                    )}
                                    <div className="price-details">
                                        <div className="price-item">
                                            {/* Price details if needed */}
                                        </div>
                                    </div>
                                </div>

                                <div className={'button-container'}>
    <button
        className={`details-button ${openCarId === car.id ? 'collapsed' : ''}`}
        onClick={() => toggleDatePicker(car.id)}
    >
        {openCarId !== car.id && (
            <div className="car-detail">
                {car.priceForSelling ? (
                    // Если задана цена для продажи, отображаем только ее
                    <div>{car.priceForSelling}$</div>
                ) : (
                    // Если цена для продажи отсутствует, отображаем аренду
                    <div>
                        {translations.productListCars.from} {car.pricePerDay}$
                    </div>
                )}
            </div>
        )}

        {/* Добавляем черту separator для всех случаев (продажа и аренда) */}
        {openCarId !== car.id && (
            <span className="separator"> | </span> /* Черта отображается для продажи и аренды */
        )}

        <div className="car-detail">
            <div>
                {openCarId === car.id ? (
                    <span className="arrow-up">
                        <svg className="custom-arrow" width="32" height="10" viewBox="0 0 32 10" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                  stroke="currentColor"
                                  stroke-opacity="1"
                                  stroke-width="2"
                                  stroke-linecap="round"/>
                        </svg>
                    </span>
                ) : (
                    translations.productListCars.liveView
                )}
            </div>
        </div>
    </button>
</div>
{/* Черта separator отображается теперь всегда */}
{openCarId !== car.id && (
    <div>
        <svg
            className="custom-arrow-down"
            width="32"
            height="10"
            viewBox="0 0 32 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                stroke="currentColor"
                stroke-opacity="1"
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    </div>
)}

                            </div>





                        ))}
                    </div>

                    <div className="pagination">
                        {pageNumbers.map((number) => (
                            <button
                                key={number}
                                onClick={() => handlePageChange(number)}
                                className={number === currentPage ? 'active' : ''}
                            >
                                {number}
                            </button>
                        ))}
                    </div>
                </div>

        </div>
    );
};

export default ProductListFlats;