import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { db } from '../../../firebase';
import { FaBluetoothB, FaHeadphones, FaSnowflake, FaUsb } from 'react-icons/fa';
import { PiEngine } from "react-icons/pi";
import { IconManualGearbox } from '@tabler/icons-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Cards/FlatCard/FlatCard.css';
import { getAuth } from 'firebase/auth';
// import EditFlatModal from '../EditModals/EditFlatModal';
import bluet from "../../ProductListCars/ProductListCarsImg/Frame 7.svg";
import usb from "../../ProductListCars/ProductListCarsImg/usb.svg";
import group from "../../ProductListCars/ProductListCarsImg/Group.svg";
import snow from "../../ProductListCars/ProductListCarsImg/snow-blowing 1.svg";
import vector from "../../ProductListCars/ProductListCarsImg/Vector.svg";
import rooms from "../../ProductListFlat/ProductListCarsImg/rooms.svg";
import floor from "../../ProductListFlat/ProductListCarsImg/floor.svg";
import bedroom from "../../ProductListFlat/ProductListCarsImg/bedrooms.svg";
import square from "../../ProductListFlat/ProductListCarsImg/square.svg";
import bathrooms from "../../ProductListFlat/ProductListCarsImg/bathrooms.svg";
import elevator from "../../ProductListFlat/ProductListCarsImg/elevator.svg";
import gas from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import heating from "../../ProductListFlat/ProductListCarsImg/heating.svg";
import pets from "../../ProductListFlat/ProductListCarsImg/pets.svg";
import balcony from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import calendarsvg from "../../ProductListCars/ProductListCarsImg/calendar-days (1) 1.svg";
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';
import EditFlatModal from "../EditModals/EditFlatModal";

const ProductListFlat = ({ activeState, role }) => {
    const { translations } = useContext(LocalizationContext);
    const [cars, setCars] = useState([]);
    const [currentUserLogin, setCurrentUserLogin] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCurrentUserLogin = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    console.log("Current User UID:", user.uid);
                    const usersCollection = collection(db, 'users');
                    const userQuery = query(usersCollection, where('uid', '==', user.uid));
                    const userSnapshot = await getDocs(userQuery);

                    if (!userSnapshot.empty) {
                        const userData = userSnapshot.docs[0].data();
                        setCurrentUserLogin(userData.login);
                    }
                }
            } catch (error) {
                console.error("Error fetching current user login: ", error);
            }
        };

        fetchCurrentUserLogin();
    }, []);

    useEffect(() => {
        const fetchCars = async () => {
            try {
                if (!role || (role === 'partner' && !currentUserLogin)) return;

                const carsCollection = collection(db, 'flats');
                let carsQuery;

                if (role === 'partner') {
                    // Для партнеров, показываем только машины текущего пользователя
                    carsQuery = query(carsCollection, where('login', '==', currentUserLogin));
                } else if (role === 'admin') {
                    // Для админов, показываем все машины со статусом 'moderation'
                    carsQuery = query(carsCollection, where('status', '==', 'moderation'));
                }

                const carSnapshot = await getDocs(carsQuery);

                const carList = carSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(car => role === 'admin' || car.status === activeState);

                setCars(carList);
            } catch (error) {
                console.error("Error fetching cars: ", error);
            }
        };

        fetchCars();
    }, [currentUserLogin, activeState, role]);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const openEditCar = (car) => {
        setSelectedCar(car.id === selectedCar?.id ? null : car); // Toggle selection
        setIsModalOpen(car.id !== selectedCar?.id); // Open modal if different car
    };

    const closeEditCar = (updatedCarData) => {
        if (selectedCar && updatedCarData) {
            try {
                setCars(prevCars =>
                    prevCars.map(car => (car.id === selectedCar.id ? updatedCarData : car))
                );
            } catch (error) {
                console.error("Ошибка при обновлении данных машины: ", error);
            }
        }
        setSelectedCar(null); // Скрыть форму редактирования
    };
    const updateCarStatusInFirestore = async (carId, newStatus) => {
        const firestore = getFirestore();
        const carDocRef = doc(firestore, 'flats', carId);

        try {
            await updateDoc(carDocRef, { status: newStatus });

            setCars(prevCars =>
                prevCars.map(car => car.id === carId ? { ...car, status: newStatus } : car)
            );
        } catch (error) {
            console.error("Error updating status: ", error);
        }
    };

    const handleStatusToggle = async (carId, currentStatus) => {
        const newStatus = currentStatus === 'approved' ? 'off' : 'approved';
        await updateCarStatusInFirestore(carId, newStatus);
        setCars(prevCars => prevCars.filter(car => car.id !== carId));
    };

    const handleApprove = async (carId) => {
        await updateCarStatusInFirestore(carId, 'approved');
        setCars(prevCars => prevCars.filter(car => car.id !== carId));
    };

    const handleDecline = async (carId, comment) => {
        // Update the car status to 'declined'
        await updateCarStatusInFirestore(carId, 'declined');

        // Update the comment field in Firestore
        const firestore = getFirestore();
        const carDocRef = doc(firestore, 'flats', carId);

        try {
            await updateDoc(carDocRef, { comment });
            setCars(prevCars =>
                prevCars.map(car => car.id === carId ? { ...car, status: 'declined', comment } : car)
            );
        } catch (error) {
            console.error("Error updating comment: ", error);
        }
        setCars(prevCars => prevCars.filter(car => car.id !== carId));
    };
    const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, isOpenCard) => {
        const isActive = feature === "Есть";
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };
        const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' };

        return (
            <div className="Bluetooth">
                <img
                    src={imgSrc}
                    style={isActive ? activeStyle : inactiveStyle}
                    alt={altText}
                />
                {isOpenCard && <div className="bluetooth-label">{featureLabel}</div>}
            </div>
        );
    };
    const reloadCars = async () => {
        try {
            if (!role || (role === 'partner' && !currentUserLogin)) return;

            const carsCollection = collection(db, 'flats');
            let carsQuery;

            if (role === 'partner') {
                carsQuery = query(carsCollection, where('login', '==', currentUserLogin));
            } else if (role === 'admin') {
                carsQuery = query(carsCollection, where('status', '==', 'moderation'));
            }

            const carSnapshot = await getDocs(carsQuery);

            const carList = carSnapshot.docs
                .map(doc => ({
                    // id: doc.id,
                    // id: doc.id,
                    ...doc.data()
                }))
                .filter(car => role === 'admin' || car.status === activeState);

            setCars(carList);
        } catch (error) {
            console.error("Error reloading cars: ", error);
        }
    };

    const openModal = (car) => {
        setSelectedCar(car);
        setIsModalOpen(true);
    };

    const closeModal = async (updatedCarData) => {
        if (selectedCar && updatedCarData) {
            try {
                setCars(prevCars =>
                    prevCars.map(car => (car.id === selectedCar.id ? updatedCarData : car))
                );
            } catch (error) {
                console.error("Error updating car data: ", error);
            }
        }

        setIsModalOpen(false);
        setSelectedCar(null);
    };

    const isAdmin = role === 'admin';


    return (
        <div className="car-list">
            {cars.map((car) => (
                <div key={car.id} className="car-card">
                    <div className="company-name-overlay">
                        <p>{car.company}</p>
                    </div>

                    {car.imageUrls && car.imageUrls.length > 0 ? (
                        <Slider {...sliderSettings}>
                            <div>
                                <img src={car.imageUrl} alt={`${car.brand} ${car.description}`} className="car-image" />
                            </div>
                            {car.imageUrls.map((url, index) => (
                                <div key={index}>
                                    <img src={url} alt={`${car.brand} image ${index}`} className="car-image" />
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <img src={car.imageUrl} alt={`${car.brand} ${car.description}`} className="car-image" />
                    )}

                    <div className="car-details">
                        <div className="LeftRight">
                            <h3 className="right">{car.brand} {car.model}</h3>
                            <p className="Class-inf">{car.carClass}</p>
                        </div>
                        <div className="disFlex elementsIcons IconGrid">

                            {renderFeatureIcon(car.rooms, '', rooms, "Rooms")}
                            <div className="fuelConsumption">
                                <div className="disFlex column">
                                    <div className="Bluetooth">
                                        <span className="fuelUnit">{car.rooms}</span>
                                        {/*{isOpenCard && (*/}
                                        {/*    <div className="bluetooth-label">{translations.productListFlats.rooms}</div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>

                            {renderFeatureIcon(car.floor, '', floor, "Floor")}
                            <div className="fuelConsumption">
                                <div className="disFlex column">
                                    <div className="Bluetooth">
                                        <span className="fuelUnit">{car.floor}</span>
                                        {/*{isOpenCard && (*/}
                                        {/*    <div className="bluetooth-label">{translations.productListFlats.rooms}</div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>

                            {renderFeatureIcon(car.bedrooms, '', bedroom, "Bedrooms")}
                            <div className="fuelConsumption">
                                <div className="disFlex column">
                                    <div className="Bluetooth">
                                        <span className="fuelUnit">{car.bedrooms}</span>
                                        {/*{isOpenCard && (*/}
                                        {/*    <div className="bluetooth-label">{translations.productListFlats.rooms}</div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>

                            {renderFeatureIcon(car.area, 'm²', square, "Square")}
                            <div className="fuelConsumption">
                                <div className="disFlex column">
                                    <div className="Bluetooth">
                                        <span className="fuelUnit">{car.area}</span>
                                        {/*{isOpenCard && (*/}
                                        {/*    <div className="bluetooth-label">{translations.productListFlats.rooms}</div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>

                            {renderFeatureIcon(car.bathrooms, '', bathrooms, "Bathrooms",)}
                            <div className="fuelConsumption">
                                <div className="disFlex column">
                                    <div className="Bluetooth">
                                        <span className="fuelUnit">{car.bathrooms}</span>
                                        {/*{isOpenCard && (*/}
                                        {/*    <div className="bluetooth-label">{translations.productListFlats.rooms}</div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>





                        </div>

                        {car.status === 'declined' && (
                            <div className="DeclinedCar">{car.comment}</div>
                        )}
                    </div>

                    <div className="btnBlock">
                        {!isAdmin && (
                            <button className="button" onClick={() => openEditCar(car)}>
                                {translations.productListCar.edit}
                            </button>
                        )}

                        {!isAdmin && car.status !== 'declined' && car.status !== 'moderation' && (
                            <button
                                className="button"
                                onClick={() => handleStatusToggle(car.id, car.status)}
                            >
                                {car.status === 'approved' ? translations.productListCar.disable : translations.productListCar.enable}
                            </button>
                        )}

                        {isAdmin && (
                            <>
                                <button className="button" onClick={() => handleApprove(car.id)}>
                                    {translations.productListCar.approve}
                                </button>
                                <button className="button" onClick={() => openEditCar(car)}>
                                    {translations.productListCar.edit}
                                </button>
                                {car.isDeclining ? (
                                    <div>
                                        <input
                                            type="text"
                                            value={car.declineComment || ''}
                                            onChange={(e) => setCars(prevCars =>
                                                prevCars.map(c =>
                                                    c.id === car.id ? { ...c, declineComment: e.target.value } : c
                                                )
                                            )}
                                            placeholder={translations.productListCar.declineReason}
                                        />
                                        <button
                                            className="button"
                                            onClick={() => handleDecline(car.id, car.declineComment)}
                                        >
                                            {translations.productListCar.submit}
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="button"
                                        onClick={() =>
                                            setCars(prevCars =>
                                                prevCars.map(c => c.id === car.id ? { ...c, isDeclining: true } : c)
                                            )
                                        }
                                    >
                                        {translations.productListCar.decline}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}

            {selectedCar && (
                <EditFlatModal
                    car={selectedCar}
                    isOpen={openEditCar}
                    onClose={closeEditCar}
                    reloadCars={reloadCars}
                />
            )}
        </div>
    );
};

export default ProductListFlat;
