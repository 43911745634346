import React, { useState, useEffect, useContext } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from '../../../firebase';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import './AddCars.css';
import ImageUpload from "../../ImageUpload/ImageUpload";
import AddMap from "../../AddMap/AddMap";
import { getAuth } from "firebase/auth";
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const AddCars = () => {
    const { translations } = useContext(LocalizationContext); // Access translations
    const [company, setCompany] = useState('');
    const [city, setCity] = useState(translations.addCars.cityPlaceholder);
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [description, setDescription] = useState('');
    const [carClass, setCarClass] = useState('');
    const [ac, setAc] = useState(translations.addCars.acPlaceholder);
    const [transmission, setTransmission] = useState(translations.addCars.transmissionPlaceholder);
    const [volume, setVolume] = useState('');
    const [fuelConsumption, setFuelConsumption] = useState('');
    const [bluetooth, setBluetooth] = useState(translations.addCars.bluetooth);
    const [usb, setUsb] = useState('USB');
    const [aux, setAux] = useState('AUX');
    const [fuel, setFuel] = useState(translations.addCars.fuelPlaceholder);
    const [year, setYear] = useState("");
    const [price1day, setPrice1day] = useState('');
    const [price2to3days, setPrice2to3days] = useState('');
    const [price4to7days, setPrice4to7days] = useState('');
    const [price8to15days, setPrice8to15days] = useState('');
    const [price16to30days, setPrice16to30days] = useState('');
    const [price31days, setPrice31days] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [multipleImages, setMultipleImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mapCoordinates, setMapCoordinates] = useState(null);
    const [deposit, setDeposit] = useState('');

    useEffect(() => {
        const savedCoordinates = localStorage.getItem('mapCoordinates');
        if (savedCoordinates) {
            setMapCoordinates(JSON.parse(savedCoordinates));
        }
    }, []);

    const uploadImage = (image) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                null,
                (error) => reject(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
                }
            );
        });
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            let imageUrl = "";
            if (singleImage) {
                imageUrl = await uploadImage(singleImage);
            }

            const imageUrls = [];
            if (multipleImages.length > 0) {
                for (const image of multipleImages) {
                    const url = await uploadImage(image);
                    imageUrls.push(url);
                }
            }

            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not logged in");
            }

            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
            const userLogin = userDoc.data()?.login || 'Unknown User';

            const carsCollectionRef = collection(db, 'cars');
            await addDoc(carsCollectionRef, {
                company,
                city,
                brand,
                model,
                description,
                ac,
                transmission,
                volume,
                carClass,
                fuelConsumption,
                bluetooth,
                usb,
                aux,
                fuel,
                year,
                price1day,
                price2to3days,
                price4to7days,
                price8to15days,
                price16to30days,
                price31days,
                imageUrl,
                imageUrls,
                deposit,
                location: mapCoordinates,
                status: "moderation",
                login: userLogin,
            });

            // Reset fields after success
            setCompany('');
            setCity(translations.addCars.cityPlaceholder);
            setBrand('');
            setModel('');
            setDescription('');
            setCarClass('');
            setAc(translations.addCars.acPlaceholder);
            setTransmission(translations.addCars.transmissionPlaceholder);
            setVolume('');
            setFuelConsumption('');
            setBluetooth(translations.addCars.bluetooth);
            setUsb('USB');
            setAux('AUX');
            setFuel(translations.addCars.fuelPlaceholder);
            setYear(translations.addCars.yearPlaceholder);
            setPrice1day('');
            setPrice2to3days('');
            setPrice4to7days('');
            setPrice8to15days('');
            setPrice16to30days('');
            setPrice31days('');
            setSingleImage(null);
            setMultipleImages([]);
            setMapCoordinates(null);
            setDeposit('')

            localStorage.removeItem('mapCoordinates');
        } catch (e) {
            console.error("Error adding document: ", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={'addCarsContainer'}>
            <h2>{translations.addCars.addCar}</h2>
            {/* Название компании */}
            <div className="input-container">
                <label htmlFor="company" className="name-add-text">{translations.addCars.nameUrCompany} <span
                    className="required">*</span>
                </label>
                <input
                    type="text"
                    id="company"
                    placeholder={translations.addCars.company}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />

            </div>

            {/* Город нахождения автомобиля */}
            <div className="input-container">
                <label htmlFor="city" className="name-add-text">{translations.addCars.carCity}</label>
                <select
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                >
                    <option disabled>{translations.addCars.cityPlaceholder}</option>
                    <option value="Batumi">{translations.addCars.cityBatumi}</option>
                    <option value="Kutaisi">{translations.addCars.cityKutaisi}</option>
                    <option value="Rustavi">{translations.addCars.cityRustavi}</option>
                    <option value="Zugdidi">{translations.addCars.cityZugdidi}</option>
                    <option value="Gori">{translations.addCars.cityGori}</option>
                    <option value="Poti">{translations.addCars.cityPoti}</option>
                    <option value="Telavi">{translations.addCars.cityTelavi}</option>
                    <option value="Akhaltsikhe">{translations.addCars.cityAkhaltsikhe}</option>
                    <option value="Samtredia">{translations.addCars.citySamtredia}</option>
                    <option value="Ozurgeti">{translations.addCars.cityOzurgeti}</option>
                    <option value="Chiatura">{translations.addCars.cityChiatura}</option>
                    <option value="Senaki">{translations.addCars.citySenaki}</option>
                    <option value="Kobuleti">{translations.addCars.cityKobuleti}</option>
                    <option value="Marneuli">{translations.addCars.cityMarneuli}</option>
                    <option value="Bolnisi">{translations.addCars.cityBolnisi}</option>
                    <option value="Khashuri">{translations.addCars.cityKashuri}</option>
                    <option value="Ambrolauri">{translations.addCars.cityAmbrolauri}</option>
                    <option value="Tqibuli">{translations.addCars.cityTqibuli}</option>
                    <option value="Gurjaani">{translations.addCars.cityGurjaani}</option>
                    <option value="Lanchkhuti">{translations.addCars.cityLanchkhuti}</option>
                    <option value="Sagarejo">{translations.addCars.citySagharejo}</option>
                    <option value="Dedoplistskaro">{translations.addCars.cityDedoplistskaro}</option>
                    <option value="Martvili">{translations.addCars.cityMartvili}</option>
                    <option value="Kareli">{translations.addCars.cityKareli}</option>
                </select>
            </div>


            {/* Марка и модель автомобиля */}
            <div className="input-container">
                <label htmlFor="brand" className="name-add-text">{translations.addCars.brandModelCar}</label>
                <label htmlFor="description" className="name-add-text">{translations.addCars.mark}</label>
                <select
                    id="brand"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                >
                    <option disabled value="">{translations.addCars.mark}</option>
                    {['Acura', 'Alfa Romeo', 'Aston Martin', 'Audi', 'Bentley', 'BMW', 'Bugatti', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Citroën', 'Dacia', 'Daewoo', 'Daihatsu', 'Dodge', 'Ferrari', 'Fiat', 'Ford', 'Genesis', 'GMC', 'Great Wall', 'Honda', 'Hummer', 'Hyundai', 'Infiniti', 'Isuzu', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini', 'Lancia', 'Land Rover', 'Lexus', 'Lincoln', 'Lotus', 'Maserati', 'Mazda', 'McLaren', 'Mercedes-Benz', 'Mini', 'Mitsubishi', 'Nissan', 'Opel', 'Peugeot', 'Polestar', 'Porsche', 'Renault', 'Rolls-Royce', 'Saab', 'SEAT', 'Škoda', 'Smart', 'Subaru', 'Suzuki', 'Tesla', 'Toyota', 'Volkswagen', 'Volvo', translations.carMarks.ZAS].map((brandOption) => (
                        <option key={brandOption} value={brandOption}>{brandOption}</option>
                    ))}
                </select>
                <input
                    type="text"
                    id="model"
                    placeholder={translations.addCars.model}
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                />

            </div>

            <label htmlFor="priceInUSD" className="name-add-text">{translations.addCars.chooseBodyType}</label>
            <select
                id="carClass"
                value={carClass}
                onChange={(e) => setCarClass(e.target.value)}
            >
                <option disabled value="">{translations.addCars.bodyType}</option>
                {[translations.carMarks.Sedan,
                    // translations.carMarks.SUV,
                    translations.carMarks.Coupe,
                    translations.carMarks.Convertible,
                    translations.carMarks.Hatchback,
                    translations.carMarks.Wagon,
                    translations.carMarks.Pickup,
                    translations.carMarks.Limousine,
                    translations.carMarks.Crossover,
                    translations.carMarks.Roadster,
                    translations.carMarks.Sports,
                    translations.carMarks.Minibus,
                    translations.carMarks.liftBack,
                    translations.carMarks.buggy,
                    translations.carMarks.microCar
                ]
                    .map((brandOption) => (
                        <option key={brandOption} value={brandOption}>{brandOption}</option>
                    ))}
            </select>
            {/* Топливо и год */}
            <div className="input-container">
                <label htmlFor="fuel" className="name-add-text">{translations.addCars.fuelType}</label>
                <select
                    id="fuel"
                    value={fuel}
                    onChange={(e) => setFuel(e.target.value)}
                >
                    <option>{translations.editCarModal.fuelGasoline}</option>
                    <option>{translations.editCarModal.fuelPropane}</option>
                    <option>{translations.editCarModal.fuelMethane}</option>
                    <option>{translations.editCarModal.fuelHybridGas}</option>
                    <option>{translations.editCarModal.fuelHybridDiesel}</option>
                    <option>{translations.editCarModal.fuelElectric}</option>
                </select>
                <label htmlFor="year" className="name-add-text">{translations.addCars.carYear}</label>
                <input
                    type="text"
                    id="year"
                    placeholder={translations.addCars.year}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />


            </div>
            {/* Объём и расход */}
            <div className="input-container">
                <label htmlFor="volume" className="name-add-text">{translations.addCars.engineCapacity}</label>
                <input
                    type="text"
                    id="volume"
                    placeholder={translations.addCars.capacity}
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                />
                <label htmlFor="fuelConsumption"
                       className="name-add-text">{translations.addCars.fuelConsumption}</label>
                <input
                    type="text"
                    id="fuelConsumption"
                    placeholder={translations.addCars.fuelConsumption100km}
                    value={fuelConsumption}
                    onChange={(e) => setFuelConsumption(e.target.value)}
                />

            </div>


            {/* Трансмиссия */}
            <div className="input-container">

                <label htmlFor="transmission" className="name-add-text">{translations.addCars.transmission}</label>
                <select
                    id="transmission"
                    value={transmission}
                    onChange={(e) => setTransmission(e.target.value)}
                >
                    <option disabled>{translations.addCars.transmission}</option>
                    <option>Automatic</option>
                    <option>Manual</option>
                    <option>CVT</option>
                </select>

            </div>


            {/* Описание и класс автомобиля */}
            <div className="input-container">
                <label htmlFor="description" className="name-add-text">{translations.addCars.carTransmission}</label>
                <input
                    type="textarea"
                    id="description"
                    placeholder={translations.addCars.description}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

            </div>


            {/* Bluetooth, USB, AUX */}
            <label className="name-add-text">{translations.addCars.carAdditionalFeatures}</label>
            <div className="input-container-checkbox">
                <label>
                    <input
                        id="ac"
                        value={ac}
                        type="checkbox"
                        checked={ac === "Есть"}
                        onChange={(e) => setAc(e.target.checked ? "Есть" : "Нет")}
                    />
                    AirConditioner
                </label>


                <label>
                    <input
                        type="checkbox"
                        checked={bluetooth === "Есть"}
                        onChange={(e) => setBluetooth(e.target.checked ? "Есть" : "Нет")}
                    />
                    Bluetooth
                </label>


                <label>
                    <input
                        type="checkbox"
                        checked={usb === "Есть"}
                        onChange={(e) => setUsb(e.target.checked ? "Есть" : "Нет")}
                    />
                    USB
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={aux === "Есть"}
                        onChange={(e) => setAux(e.target.checked ? "Есть" : "Нет")}
                    />
                    AUX
                </label>

            </div>


            {/* Цены по дням */}
            <div className="input-container">
                <label htmlFor="priceInUSD" className="name-add-text">{translations.addCars.priceInUSD}</label>
                <label htmlFor="price1day" className="name-add-text">{translations.addCars.pricePer1day}</label>
                <input
                    type="text"
                    id="price1day"
                    placeholder={translations.addCars.oneDay}
                    value={price1day}
                    onChange={(e) => setPrice1day(e.target.value)}
                />
                <label htmlFor="price2to3days" className="name-add-text">{translations.addCars.priceforTwoThreedays}:</label>
                <input
                    type="text"
                    id="price2to3days"
                    placeholder={translations.addCars.twoThreeDays}
                    value={price2to3days}
                    onChange={(e) => setPrice2to3days(e.target.value)}
                />

            </div>

            {/* Продолжение цен */}
            <div className="input-container">
                <label htmlFor="price4to7days"
                       className="name-add-text">{translations.addCars.priceForFourSevenDays}:</label>
                <input
                    type="text"
                    id="price4to7days"
                    placeholder={translations.addCars.fourSevenDays}
                    value={price4to7days}
                    onChange={(e) => setPrice4to7days(e.target.value)}
                />
                <label htmlFor="price8to15days"
                       className="name-add-text">{translations.addCars.priceForEightFiftheendays}:</label>
                <input
                    type="text"
                    id="price8to15days"
                    placeholder={translations.addCars.eightFiftheenDays}
                    value={price8to15days}
                    onChange={(e) => setPrice8to15days(e.target.value)}
                />

            </div>

            {/* Цена за более длительные сроки */}
            <div className="input-container">
                <label htmlFor="price16to30days"
                       className="name-add-text">{translations.addCars.priceForSixtheenThirtyDays}:</label>
                <input
                    type="text"
                    id="price16to30days"
                    placeholder={translations.addCars.sixtheenThirtyDays}
                    value={price16to30days}
                    onChange={(e) => setPrice16to30days(e.target.value)}
                />
                <label htmlFor="price31days"
                       className="name-add-text">{translations.addCars.rentalPriceForOnemonth}:</label>
                <input
                    type="text"
                    id="price31days"
                    placeholder={translations.addCars.thirthyOneday}
                    value={price31days}
                    onChange={(e) => setPrice31days(e.target.value)}
                />


                <div className="input-container">
                    <label htmlFor="deposit" className="name-add-text">{translations.addCars.specifyDeposit}</label>
                    <input
                        type="text"
                        placeholder={translations.editCarModal.depositPlaceholder}
                        name="deposit"
                        value={deposit}
                        onChange={(e) => setDeposit(e.target.value)}
                    />
                </div>

            </div>


            <ImageUpload
                onSingleImageChange={setSingleImage}
                onMultipleImagesChange={(files) => setMultipleImages(Array.from(files))}
            />
            <div>
                <AddMap onMapDataChange={setMapCoordinates}></AddMap>
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    style={{width: '100%'}}
                >
                    {loading ? translations.addCars.loading : translations.addCars.submit}
                </button>
            </div>
        </div>
    );
};

export default AddCars;
