import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CategoryBarCategories/CategoryBar.css';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext'
import { CategoryContext} from "../../../App";
import { useSwipeToScroll } from '../../hooks/useSwipeToScroll';


const categories = [
    { id: 'cars', icon: 'fas fa-car', translationKey: 'cars' },
    { id: 'flats', icon: 'fas fa-building', translationKey: 'propertyRental' }

];

const CategoryBarMap = ({ onCategoryChange, isMapView }) => {
    const { translations } = useContext(LocalizationContext);
    const { activeCategories, setActiveCategories } = useContext(CategoryContext);
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState(true);
    const [isBarHidden, setIsBarHidden] = useState(false);
    const [scrollPos, setScrollPos] = useState(window.scrollY);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const categoryContainerRef = useRef(null);
    const isInitialRender = useRef(true);

    // Custom hook for swipe functionality
    const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipeToScroll();

    useEffect(() => {
        const storedCategories = sessionStorage.getItem('activeCategories');
        if (storedCategories) {
            try {
                const parsedCategories = JSON.parse(storedCategories);
                if (Array.isArray(parsedCategories)) {
                    setActiveCategories(parsedCategories);
                    return; // Прекращаем выполнение, если есть сохраненные категории
                }
            } catch (error) {
                console.error("Failed to parse activeCategories from sessionStorage", error);
            }
        }
        // Если данных в sessionStorage нет, устанавливаем 'cars' как активную категорию
        const defaultCategories = ['cars'];
        setActiveCategories(defaultCategories);
        sessionStorage.setItem('activeCategories', JSON.stringify(defaultCategories));
    }, [setActiveCategories]);

    useEffect(() => {
        if (onCategoryChange) {
            onCategoryChange(activeCategories);
        }
    }, [activeCategories, onCategoryChange]);

    const handleCategoryClick = (category) => {
        switch (category) {
            case 'cars':
                handleCategorySelection(category);
                break;

            // case 'flats':
            //     handleCategorySelection(category);
            //     break;



            // case 'sea-tours':
            // case 'restaurants':
            // case 'beauty':
            // case 'health-sport':
            // case 'recreation':
            case 'flats':
                handleCategorySelection(category);
                break;

            default:
                // Navigate to the 'unavailable-category' page for unsupported categories
                setPopupMessage(`This category is under moderation: ${category}`);
                setShowPopup(true);
                return;
        }
    };

    const handleCategorySelection = (category) => {
        const updatedCategories = activeCategories.includes(category)
            ? [] // Если категория уже выбрана, очищаем выбор
            : [category]; // Иначе устанавливаем её как единственную активную

        setActiveCategories(updatedCategories);
        sessionStorage.setItem('activeCategories', JSON.stringify(updatedCategories));

        onCategoryChange && onCategoryChange(updatedCategories);
    };

    useEffect(() => {
        onCategoryChange(activeCategories);
    }, [activeCategories, onCategoryChange]);

    const toggleShowAll = () => {
        setShowAll((prev) => !prev);
    };

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setIsBarHidden(currentScrollPos > scrollPos && currentScrollPos > 100);
        setScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPos]);

    const toggleCategoryBar = () => {
        setIsBarHidden((prev) => !prev);
    };

    const renderCategoryItems = () => (
        <div
            className="category-items-container"
            ref={categoryContainerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd(categoryContainerRef)}
        >
            {categories.map(({ id, icon, translationKey }) => (
                <div key={id} className={`category-bar__item ${activeCategories.includes(id) ? 'active' : ''}`}>
                    <button onClick={() => handleCategoryClick(id)} className="category-button">
                        <i className={icon}></i>
                        <span className="category-name">
                            {translations.category && translations.category[translationKey]}
                        </span>
                    </button>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {isMapView && (
                <div className="toggle-button-container">
                    <button onClick={toggleShowAll} className="toggle-category-button">
                        <i className={`fas ${showAll ? 'fa-times' : 'fa-sliders-h'}`}></i>
                    </button>
                </div>
            )}
            <div className={`category-bar ${isBarHidden ? 'hidden' : ''} ${isMapView ? 'map-view' : 'product-list'}`}>
                {!isMapView ? renderCategoryItems() : showAll && renderCategoryItems()}
            </div>

            <button className={`scroll-arrow ${!isBarHidden ? 'hidden' : ''}`} onClick={toggleCategoryBar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11" fill="none">
                    <path
                        d="M1 1.5L10 8.5L19 1.5"
                        stroke="var(--text-color-primary)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

        </>
    );
};

export default CategoryBarMap;
