import React, { useCallback, useState } from 'react';
import axios from 'axios';
import '../Form.css';
import partnerForm from "../../Form/PartnerForm/PartnerForm"; // Подключаем стили

const CarForm = ({ onClose, objectId, carModel,carName, daysCount, price, carCompany}) => { // Добавляем objectId как пропс
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState(false);
    const [telegram, setTelegram] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [instagramLogin, setInstagramLogin] = useState('');
    const [telegramLogin, setTelegramLogin] = useState('');
    const [whatsappLogin, setWhatsappLogin] = useState('');

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false); // Для отображения анимации успеха

    const chatId = '-1002135710194'; // Ваш chat_id
    const botToken = '7356584757:AAFMITZXblh8k-FsOJdUK4yr62sUmAxG4gw'; // Ваш токен бота
    const total =  daysCount * price;
    const [errorMessage, setErrorMessage] = useState('');
     const validateFields = () => {
        if (!phone && !instagramLogin && !telegramLogin && !whatsappLogin) {
            setErrorMessage('Укажите контактный номер телефона или логин (Telegram, Instagram, Whatsapp)');
            return false;
        }
        setErrorMessage('');
        return true;
    };
    // Функция для отправки данных в Telegram
    const onSendData = useCallback(() => {
         if (!validateFields()) return;
        const message = `
         🚗  Аренда авто:
         
         
   Info about client:
👤 Name: ${name}
📞 Phone: ${phone}
📸 Instagram: ${instagram ? `Yes (Login: ${instagramLogin})` : 'No'}
📱 Telegram: ${telegram ? `Yes (Login: ${telegramLogin})` : 'No'}
💬 Whatsapp: ${whatsapp ? `Yes (Login: ${whatsappLogin})` : 'No'}
 
 
 Info about car:
🚗 Car Name: ${carName}
🚙 Car Model: ${carModel}
🆔 Object ID: ${objectId}
🗓️ Day Count: ${daysCount}
💵 Price Per Day: ${price}
💰 Total: ${total}

🏢Company: ${carCompany}
`;


console.log(message);

        axios
            .post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            chat_id: chatId,
            text: message,
        })
            .then(response => {
                console.log('Message sent successfully:', response);
                setIsSubmitted(true);
                setShowSuccess(true); // Показываем анимацию успеха
                // setTimeout(() => {
                //     onClose(); // Закрываем модальное окно после задержки
                // }, 3000); // Закрыть через 3 секунды
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    }, [name, phone, instagram, telegram, whatsapp, instagramLogin, telegramLogin, whatsappLogin, botToken, chatId, objectId, onClose,carCompany]);

    // Обработчики изменений инпутов
    const onChangeName = (e) => setName(e.target.value);
    const onChangePhone = (e) => setPhone(e.target.value);
    const onChangeInstagram = (e) => setInstagram(e.target.checked);
    const onChangeTelegram = (e) => setTelegram(e.target.checked);
    const onChangeWhatsapp = (e) => setWhatsapp(e.target.checked);
    const onChangeInstagramLogin = (e) => setInstagramLogin(e.target.value);
    const onChangeTelegramLogin = (e) => setTelegramLogin(e.target.value);
    const onChangeWhatsappLogin = (e) => setWhatsappLogin(e.target.value);

    return (
        <div className="Form">
            <div className={isSubmitted ? 'hidden' : 'visible'}>
                <h2 className="h2">Забронировать</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Сообщение об ошибке */}

                <div className="input-group">
                    <input
                        className="input"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={onChangeName}
                        required
                    />
                </div>

                <div className="input-group">
                    <input
                        className="input"
                        type="text"
                        placeholder="Phone number"
                        value={phone}
                        onChange={onChangePhone}
                        required
                    />

                </div>


                <div className="input-group">
                    <label>
                        <input type="checkbox" checked={instagram} onChange={onChangeInstagram} />
                        Instagram
                    </label>
                    {instagram && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Instagram Login"
                            value={instagramLogin}
                            onChange={onChangeInstagramLogin}
                        />
                    )}
                </div>

                <div className="input-group">
                    <label>

                        <input type="checkbox" checked={telegram} onChange={onChangeTelegram} />
                        Telegram
                    </label>
                    {telegram && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Telegram Login"
                            value={telegramLogin}
                            onChange={onChangeTelegramLogin}
                        />
                    )}
                </div>

                <div className="input-group">
                    <label>
                        <input type="checkbox" checked={whatsapp} onChange={onChangeWhatsapp} />
                        Whatsapp
                    </label>
                    {whatsapp && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Whatsapp Login"
                            value={whatsappLogin}
                            onChange={onChangeWhatsappLogin}
                        />
                    )}
                </div>

                <button className="send-button" onClick={onSendData}>
                    Send to Telegram
                </button>
            </div>

            {/* Анимация успеха */}
            {showSuccess && (
                <div className="success-animation">
                    <div className="checkmark">
                        <span>&#10004;</span>
                    </div>
                    <h3>Благодарю, ваша заявка отправлена!</h3>
                    {/* Кнопка для закрытия уведомления */}

                    <button
                        className="close-notification"
                        onClick={() => window.open('https://t.me/weeekendteam', '_blank')}
                    >
                        Поддержка
                    </button>
                </div>
            )}
        </div>
    );
};

export default CarForm;
