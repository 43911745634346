import React, { useState } from 'react';
import DatePicker from '../../ProductListCars/DatePicker/DatePicker';
import Slider from 'react-slick'; // Импортируем слайдер

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../ProductListCars/DatePicker/DatePicker.css';
import './CarCard.css';

import usb from '../../ProductListCars/ProductListCarsImg/usb.svg';
import group from '../../ProductListCars/ProductListCarsImg/Group.svg';
import snow from '../../ProductListCars/ProductListCarsImg/snow-blowing 1.svg';
import vector from '../../ProductListCars/ProductListCarsImg/Vector.svg';
import bluet from '../../ProductListCars/ProductListCarsImg/Frame 7.svg';
import calendarsvg from '../../ProductListCars/ProductListCarsImg/calendar-days (1) 1.svg';

// Настройки для слайдера
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, isOpenCard) => {
    const isActive = feature === "Есть";
    const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };
    const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' };

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={isActive ? activeStyle : inactiveStyle}
                alt={altText}
            />
            {isOpenCard && <div className="bluetooth-label">{featureLabel}</div>}
        </div>
    );
};

const CarCard = ({ car }) => {
    const [isOpenCard, setIsOpenCard] = useState(false);
    const carCardClasses = `car-card ${isOpenCard ? 'card-opened': ''}`;
    return (
        <div key={car.id} className={carCardClasses}>
            <div className="company-name-overlay">
                <p>{car.company}</p>
            </div>
            {/* Проверяем наличие imageUrls, если они есть, используем слайдер */}
            {car.imageUrls?.length ? (
                <Slider {...sliderSettings}>
                    {/* Главное изображение */}
                    <div>
                        <img src={car.imageUrl} alt={`${car.brand} ${car.description}`}
                            className="car-image"
                            loading={"lazy"}/>
                    </div>
                    {/* Дополнительные изображения */}
                    {car.imageUrls.map((url, index) => (
                        <div key={index}>
                            <img src={url} alt={`${car.brand} image ${index}`} className="car-image"
                                loading={"lazy"}/>
                        </div>
                    ))}
                </Slider>
            ) : (
                <img src={car.imageUrl} alt={`${car.brand} ${car.description}`} className="car-image"/>
            )}
            <div className="car-details">
                <div className="LeftRight">
                    <div className={'disFlex brandvolume'}><h3
                        className="right">{car.brand} {car.model} </h3> <p>{car.volume}L</p></div>
                    <p>{car.transmission}</p>
                </div>
                <div className="car-details">
                    <div className="disFlex elementsIcons IconGrid">
                        {renderFeatureIcon(car.bluetooth, "Bluetooth", bluet, "Bluetooth", isOpenCard)}
                        {renderFeatureIcon(car.usb, "USB", usb, "USB", isOpenCard)}
                        {renderFeatureIcon(car.aux, "AUX", group, "AUX", isOpenCard)}
                        {renderFeatureIcon(car.ac, "Conditioner", snow, "Conditioner", isOpenCard)}
                        {renderFeatureIcon(car.usb, car.fuel, vector, "Fuel", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex">
                                <div className="Bluetooth">
                                    <span className="fuelNumber">{car.fuelConsumption}</span>
                                    <span className="fuelUnit">l/km</span>
                                    {isOpenCard &&
                                        <div className="bluetooth-label">Fuel Flow</div>}
                                </div>
                            </div>
                        </div>

                        {renderFeatureIcon(car.usb, car.year, calendarsvg, "Year", isOpenCard)}
                    </div>
                </div>
                {isOpenCard && (
                    <DatePicker
                        price1day={car.price1day}
                        price2to3days={car.price2to3days}
                        price4to7days={car.price4to7days}
                        price8to15days={car.price8to15days}
                        price16to30days={car.price16to30days}
                        price31plusdays={car.price31plusdays}
                        objectID={car.id}
                        carName={car.brand}
                        carModel={car.model}
                    />
                )}
                <div className="price-details">
                    <div className="price-item">
                        {/* Price details if needed */}
                    </div>
                </div>
            </div>
            <div className={'button-container'}>
                <button
                    className={`details-button ${isOpenCard ? 'collapsed' : ''}`}
                    onClick={() => {
                        setIsOpenCard(!isOpenCard);
                    }}
                >
                    {!isOpenCard && (
                        <div className="car-detail">
                            <div>From {car.price1day}$</div>
                        </div>
                    )}
                    {!isOpenCard && <span className="separator"> | </span>} {/* Черта отображается только если карточка закрыта */}
                    <div className="car-detail">
                        <div>
                            {isOpenCard ? (
                                <span className="arrow-up">
                                    <svg className="custom-arrow" width="32" height="10" viewBox="0 0 32 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                            stroke="currentColor" /* Цвет можно будет изменять через CSS */
                                            strokeOpacity="1"
                                            strokeWidth="2"
                                            strokeLinecap="round"/>
                                    </svg>
                                </span>
                            ) : (
                                "GET NOW"
                            )}
                        </div>
                    </div>
                </button>
            </div>
            {!isOpenCard &&
                <div className="date-icon">
                    <svg
                        className="custom-arrow-down"
                        width="32"
                        height="10"
                        viewBox="0 0 32 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                            stroke="currentColor"
                            strokeOpacity="1"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            }
        </div>
    );
};

export default CarCard;