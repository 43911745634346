import React, {useContext, useState} from 'react';
import DatePicker from '../../ProductListFlat/DatePicker/DatePickerFlat';
import Slider from 'react-slick'; // Импортируем слайдер
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../ProductListCars/DatePicker/DatePicker.css';
import './FlatCard.css';
import calendarsvg from '../../ProductListCars/ProductListCarsImg/calendar-days (1) 1.svg';
import rooms from "../../ProductListFlat/ProductListCarsImg/rooms.svg";
import floor from "../../ProductListFlat/ProductListCarsImg/floor.svg";
import bedroom from "../../ProductListFlat/ProductListCarsImg/bedrooms.svg";
import square from "../../ProductListFlat/ProductListCarsImg/square.svg";
import bathrooms from "../../ProductListFlat/ProductListCarsImg/bathrooms.svg";
import elevator from "../../ProductListFlat/ProductListCarsImg/elevator.svg";
import gas from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import heating from "../../ProductListFlat/ProductListCarsImg/heating.svg";
import pets from "../../ProductListFlat/ProductListCarsImg/pets.svg";
import balcony from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import {LocalizationContext} from "../../../Services/localization/LocalizationContext";
import bluet from "../../ProductListCars/ProductListCarsImg/Frame 7.svg";
import view from "../../ProductListFlat/ProductListCarsImg/view.svg";
import DatePickerFlat from "../../ProductListFlat/DatePicker/DatePickerFlat";

// Настройки для слайдера
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, isOpenCard) => {
    const isActive = feature === "Есть";
    const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };
    const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' };

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={isActive ? activeStyle : inactiveStyle}
                alt={altText}
            />
            {isOpenCard && <div className="bluetooth-label">{featureLabel}</div>}
        </div>
    );
};
const renderFeatureIconUp = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={activeStyle}
                alt={altText}
            />
            <div className="bluetooth-label">{featureLabel}</div>
        </div>
    );
};


const FlatCard = ({ flat }) => {
    const { translations } = useContext(LocalizationContext);
    const [isOpenCard, setIsOpenCard] = useState(false);
    const flatCardClasses = `flat-card ${isOpenCard ? 'card-opened': ''}`;
    return (
    <div key={flat.id} className={flatCardClasses}>
        <div className="company-name-overlay">
            <p>{flat.company}</p>
        </div>
        {/* Проверяем наличие imageUrls и то, открыт ли календарь */}
        {!isOpenCard && flat.imageUrls?.length ? (
            <Slider {...sliderSettings}>
                {/* Главное изображение */}
                <div>
                    <img
                        src={flat.imageUrl}
                        alt={`${flat.city} ${flat.description}`}
                        className="flat-image"
                        loading={"lazy"}
                    />
                </div>
                {/* Дополнительные изображения */}
                {flat.imageUrls.map((url, index) => (
                    <div key={index}>
                        <img
                            src={url}
                            alt={`${flat.city} image ${index}`}
                            className="flat-image"
                            loading={"lazy"}
                        />
                    </div>
                ))}
            </Slider>
        ) : !isOpenCard && (
            <img
                src={flat.imageUrl}
                alt={`${flat.city} ${flat.description}`}
                className="car-image"
            />
        )}

        <div className="flat-card-details">
            <div className="flat-details">
                <div className="LeftRight">
                    <div className={'disFlex brandvolume'}>
                        <h3 className="right"> {flat.propertyType} в {flat.city}</h3>
                        <p>{flat.dealType}</p>
                    </div>
                </div>

                {!isOpenCard && (
                    <div className="disFlex elementsIcons IconGrid">
                        {renderFeatureIconUp(flat.rooms, '', rooms, "Rooms", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex column">
                                <div className="Bluetooth">
                                    <span className="fuelUnit">{flat.rooms}</span>
                                    {isOpenCard && (
                                        <div className="bluetooth-label">{translations.productListFlats.rooms}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {renderFeatureIconUp(flat.floor, '', floor, "Floor", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex column">
                                <div className="Bluetooth">
                                    <span className="fuelUnit">{flat.floor}</span>
                                    {isOpenCard && (
                                        <div className="bluetooth-label">{translations.productListFlats.floor}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {renderFeatureIconUp(flat.bedrooms, flat.bedrooms, bedroom, "Bedrooms", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex column">
                                <div className="Bluetooth">
                                    <span className="fuelUnit">{flat.bedrooms}</span>
                                    {isOpenCard && (
                                        <div className="bluetooth-label">{translations.productListFlats.bedrooms}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {renderFeatureIconUp(flat.bathrooms, '', bathrooms, "Bathrooms", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex column">
                                <div className="Bluetooth">
                                    <span className="fuelUnit">{flat.bathrooms}</span>
                                    {isOpenCard && (
                                        <div className="bluetooth-label">{translations.productListFlats.square}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {renderFeatureIconUp(flat.view, '', view, "View", isOpenCard)}
                        <div className="fuelConsumption">
                            <div className="disFlex column">
                                <div className="Bluetooth">
                                    <span className="fuelUnit">{flat.view}</span>
                                    {isOpenCard && (
                                        <div className="bluetooth-label">{translations.productListFlats.square}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/*{renderFeatureIconUp(car.view, ` ${car.view}`, view, "Bathrooms", openCarId, car.id)}*/}

                    </div>
                )}
            </div>
        </div>
        {isOpenCard && (
            <DatePickerFlat
                price1day={flat.pricePerDay}
                price2to3days={flat.pricePer3Days}
                price4to7days={flat.pricePerWeek}
                price8to15days={flat.pricePer14Days}
                price15to30days={flat.pricePerMonth}
                objectID={flat.id}
                carName={flat.description}
                carModel={flat.description}
                priceForSelling={flat.priceForSelling}
            />
        )}
        <div className="price-details">
            <div className="price-item">
                {/* Price details if needed */}
            </div>
        </div>

        <div className={'button-container'}>
    <button
        className={`details-button ${isOpenCard ? 'collapsed' : ''}`}
        onClick={() => {
            setIsOpenCard(!isOpenCard);
        }}
    >
        {!isOpenCard && (
            <div className="flat-detail">
                {flat.priceForSelling ? (
                    // Если задана цена для продажи, отображаем только ее
                    <div>{flat.priceForSelling}$</div>
                ) : (
                    // Если цена для продажи отсутствует, отображаем аренду
                    <div>From {flat.pricePerDay}$</div>
                )}
            </div>
        )}

        {/* Добавляем черту separator для всех случаев (продажа и аренда) */}
        {!isOpenCard && (
            <span className="separator"> | </span> /* Черта отображается для продажи и аренды */
        )}

        <div className="flat-detail">
            <div>
                {isOpenCard ? (
                    <span className="arrow-up">
                        <svg
                            className="custom-arrow"
                            width="32"
                            height="10"
                            viewBox="0 0 32 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                stroke="currentColor"
                                strokeOpacity="1"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </span>
                ) : (
                    translations.productListCars.liveView
                )}
            </div>
        </div>
    </button>
</div>

{/* Черта separator отображается всегда */}
{!isOpenCard && (
    <div className="date-icon">
        <svg
            className="custom-arrow-down"
            width="32"
            height="10"
            viewBox="0 0 32 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                stroke="currentColor"
                strokeOpacity="1"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    </div>
)}


    </div>
);

            };

            export default FlatCard;