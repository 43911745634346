import React, { useState, useContext, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from '../../../firebase';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import ImageUpload from "../../ImageUpload/ImageUpload";
import AddMap from "../../AddMap/AddMap";
import './AddFlats.css';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const AddFlats = () => {
    const {translations} = useContext(LocalizationContext);
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dealType, setDealType] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [condition, setCondition] = useState('');
    const [area, setArea] = useState('');
    const [rooms, setRooms] = useState('');
    const [floor, setFloor] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [petsAllowed, setPetsAllowed] = useState(false);
    const [balcony, setBalcony] = useState('');
    const [hasElevator, setHasElevator] = useState(false);
    const [hasGas, setHasGas] = useState(false);
    const [pricePerDay, setPricePerDay] = useState('');
    const [priceForSelling, setPriceForSelling] = useState('');
    const [pricePer3Days, setPricePer3Days] = useState('');
    const [pricePerWeek, setPricePerWeek] = useState('');
    const [pricePer14Days, setPricePer14Days] = useState('');
    const [pricePerMonth, setPricePerMonth] = useState('');
    const [address, setAddress] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [multipleImages, setMultipleImages] = useState([]);
    const [mapCoordinates, setMapCoordinates] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalFloors, setTotalFloors] = useState(''); // Добавляем состояние для общего количества этажей
    const [heatingOptions, setHeatingOptions] = useState([]);
    const [view, setView] = useState([]);



const handleHeatingChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
        setHeatingOptions((prev) => [...prev, value]);
    } else {
        setHeatingOptions((prev) => prev.filter(option => option !== value));
    }
};

    useEffect(() => {
        const savedCoordinates = localStorage.getItem('mapCoordinates');
        if (savedCoordinates) {
            setMapCoordinates(JSON.parse(savedCoordinates));
        }
    }, []);
    const uploadImage = (image) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                null,
                (error) => reject(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
                }
            );
        });
    };
    const handleSubmit = async () => {
        setLoading(true);

        try {
            let imageUrl = "";
            if (singleImage) {
                imageUrl = await uploadImage(singleImage);
            }

            const imageUrls = [];
            if (multipleImages.length > 0) {
                for (const image of multipleImages) {
                    const url = await uploadImage(image);
                    imageUrls.push(url);
                }
            }
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not logged in");
            }

            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
            const userLogin = userDoc.data()?.login || 'Unknown User';

            const flatsCollectionRef = collection(db, 'flats');
            await addDoc(flatsCollectionRef, {
                company,
                city,
                title,
                description,
                dealType,
                propertyType,
                condition,
                area,
                rooms,
                floor,
                bathrooms,
                bedrooms,
                petsAllowed,
                balcony,
                view,
                hasElevator,
                hasGas,
                pricePerDay,
                pricePer3Days,
                pricePerWeek,
                pricePer14Days,
                pricePerMonth,
                imageUrl,
                imageUrls,
                address,
                heatingOptions,
                priceForSelling,

                location: mapCoordinates,
                status: "moderation",
                login: userLogin,
            });

            // Reset fields
            setCompany('');
            setCity('');
            setTitle('');
            setDescription('');
            setDealType('');
            setPropertyType('');
            setCondition('');
            setArea('');
            setRooms('');
            setFloor('');
            setBathrooms('');
            setBedrooms('');
            setPetsAllowed(false);
            setBalcony('');
            setView('');
            setHasElevator(false);
            setHeatingOptions('')
            setHasGas(false);
            setPricePerDay('');
            setPricePer3Days('');
            setPricePerWeek('');
            setPricePer14Days('');
            setPricePerMonth('');
            setSingleImage(null);
            setMultipleImages([]);
            setMapCoordinates(null);
            localStorage.removeItem('mapCoordinates');
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="addFlatsContainer">

            <h2>{translations.addFlats.addRealState}</h2>

            {/* Название компании */}
            <div className="input-container">
                <label htmlFor="company" className="name-add-text">{translations.addCars.nameUrCompany} <span
                    className="required">*</span>
                </label>
                <input
                    type="text"
                    id="company"
                    placeholder={translations.addCars.company}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />

            </div>
            {/* Город нахождения автомобиля */}
            <div className="input-container">
                <label htmlFor="city" className="name-add-text">{translations.addFlats.flatCity}</label>

                <select
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                >
                    <option value="City">{translations.addCars.cityPlaceholder}</option>
                    <option value="Batumi">{translations.addCars.cityBatumi}</option>
                    <option value="Kutaisi">{translations.addCars.cityKutaisi}</option>
                    <option value="Rustavi">{translations.addCars.cityRustavi}</option>
                    <option value="Zugdidi">{translations.addCars.cityZugdidi}</option>
                    <option value="Chakvi">{translations.addCars.cityChakvi}</option>
                    <option value="Gori">{translations.addCars.cityGori}</option>
                    <option value="Poti">{translations.addCars.cityPoti}</option>
                    <option value="Telavi">{translations.addCars.cityTelavi}</option>
                    <option value="Akhaltsikhe">{translations.addCars.cityAkhaltsikhe}</option>
                    <option value="Samtredia">{translations.addCars.citySamtredia}</option>
                    <option value="Ozurgeti">{translations.addCars.cityOzurgeti}</option>
                    <option value="Chiatura">{translations.addCars.cityChiatura}</option>
                    <option value="Senaki">{translations.addCars.citySenaki}</option>
                    <option value="Kobuleti">{translations.addCars.cityKobuleti}</option>
                    <option value="Marneuli">{translations.addCars.cityMarneuli}</option>
                    <option value="Bolnisi">{translations.addCars.cityBolnisi}</option>
                    <option value="Khashuri">{translations.addCars.cityKashuri}</option>
                    <option value="Ambrolauri">{translations.addCars.cityAmbrolauri}</option>
                    <option value="Tqibuli">{translations.addCars.cityTqibuli}</option>
                    <option value="Gurjaani">{translations.addCars.cityGurjaani}</option>
                    <option value="Lanchkhuti">{translations.addCars.cityLanchkhuti}</option>
                    <option value="Sagarejo">{translations.addCars.citySagharejo}</option>
                    <option value="Dedoplistskaro">{translations.addCars.cityDedoplistskaro}</option>
                    <option value="Martvili">{translations.addCars.cityMartvili}</option>
                    <option value="Kareli">{translations.addCars.cityKareli}</option>
                </select>
            </div>
            <label htmlFor="view" className="name-add-text">{translations.addFlats.address}</label>
            <input placeholder={translations.addFlats.address} value={address}
                   onChange={(e) => setAddress(e.target.value)}/>


            <label htmlFor="title" className="name-add-text">{translations.addFlats.selectTitle}</label>
            <input type="text" placeholder={translations.addFlats.title} value={title}
                   onChange={(e) => setTitle(e.target.value)}/>

            <label htmlFor="description" className="name-add-text">{translations.addFlats.description}</label>
            <textarea placeholder={translations.addFlats.description} value={description}
                      onChange={(e) => setDescription(e.target.value)}/>
            <label htmlFor="transaction-type" className="name-add-text">{translations.addFlats.transactionType}</label>

            <select value={dealType} onChange={(e) => setDealType(e.target.value)}>
                <option value="тип сделки">{translations.productListFlats.dealType}</option>
                <option value="аренда">{translations.productListFlats.rent}</option>
                <option value="продажа">{translations.productListFlats.sale}</option>
                <option value="посуточно">{translations.productListFlats.daily}</option>
            </select>
            <label htmlFor="property-type" className="name-add-text">{translations.addFlats.propertyType}</label>

            <select value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                <option value="Тип недвижимости">{translations.productListFlats.propertyType}</option>
                <option value="Квартира">{translations.productListFlats.flat}</option>
                <option value="Частный дом">{translations.productListFlats.privateHouse}</option>
                <option value="Вилла">{translations.productListFlats.villa}</option>
                <option value="Отель">{translations.productListFlats.hotel}</option>
                <option value="Коммерческое помещение">{translations.productListFlats.commercialSpace}</option>
                <option value="Участок">{translations.productListFlats.plot}</option>
                <option value="Коворкинг">{translations.productListFlats.coworking}</option>
            </select>
            <label htmlFor="condition" className="name-add-text">{translations.addFlats.condition}</label>

            <select value={condition} onChange={(e) => setCondition(e.target.value)}>
                <option value="Cостояние">{translations.addFlats.condition}</option>
                <option value="Новое">{translations.addFlats.new}</option>
                <option value="Вторичное">{translations.addFlats.secondary}</option>
                <option value="Черный каркас">{translations.addFlats.blackFrame}</option>
                <option value="Белый каркас">{translations.addFlats.whiteFrame}</option>
            </select>
            <label htmlFor="area" className="name-add-text">{translations.addFlats.area}</label>
            <input type="text" placeholder={translations.addFlats.area} value={area}
                   onChange={(e) => setArea(e.target.value)}/>
            <label htmlFor="city" className="name-add-text">{translations.addFlats.selectRooms}</label>
            <select value={rooms} onChange={(e) => setRooms(e.target.value)}>
                <option value="rooms">{translations.addFlats.rooms}</option>
                <option value="1+1">1+1</option>
                <option value="2+1">2+1</option>
                <option value="3+1">3+1</option>
                <option value="4+1+">4+1+</option>
            </select>

            {/*<label htmlFor="floor" className="name-add-text">{translations.addFlats.floor}</label>*/}
            {/*<input type="number" placeholder={translations.addFlats.floor} value={floor}*/}
            {/*       onChange={(e) => setFloor(e.target.value)}/>*/}
            <div className="block-container">
                <label htmlFor="totalFloors" className="name-add-text">
                    {translations.addFlats.selectTotalFloors}
                </label>
                <input
                    type="text"
                    placeholder={translations.addFlats.selectTotalFloors}
                    value={totalFloors}
                    onChange={(e) => setTotalFloors(e.target.value)}
                />

                <label htmlFor="floor" className="name-add-text">{translations.addFlats.floor}</label>
                <select
                    value={floor}
                    onChange={(e) => setFloor(e.target.value)}
                    disabled={!totalFloors}
                >
                    <option value="" disabled hidden>Выберите этаж</option>
                    {Array.from({length: totalFloors}, (_, index) => (
                        <option key={index} value={index + 1}>{index + 1}</option>
                    ))}
                </select>
            </div>


            <label htmlFor="bathrooms" className="name-add-text">{translations.addFlats.bathrooms}</label>
            <input type="text" placeholder={translations.addFlats.bathrooms} value={bathrooms}
                   onChange={(e) => setBathrooms(e.target.value)}/>
            <label htmlFor="sleeping-place" className="name-add-text">{translations.addFlats.sleepingPlaces}</label>
            <input type="text" placeholder={translations.addFlats.sleepingPlaces} value={bedrooms}
                   onChange={(e) => setBedrooms(e.target.value)}/>
            <label>
                <div className="input-container-checkbox">
                    <label className="name-add-text">{translations.addFlats.petsAllowed}</label>

                    <input type="checkbox"
                           checked={petsAllowed}
                           onChange={(e) => setPetsAllowed(e.target.checked)}
                    />
                </div>
            </label>
            <label htmlFor="balcony" className="name-add-text">{translations.addFlats.Balcony}</label>
            <select value={balcony} onChange={(e) => setBalcony(e.target.value)}>
                <option value="Балкон,терраса,балкон+терраса">{translations.addFlats.Balcony}</option>
                <option value="Балкон">{translations.addFlats.balcony}</option>
                <option value="Терраса">{translations.addFlats.terrace}</option>
                <option value="Балкон+Терраса">{translations.addFlats.balconyTerrace}</option>
            </select>
            <label htmlFor="view" className="name-add-text">{translations.addFlats.view}</label>
            <div className="checkbox-group">
                {[
                    {value: "На город", label: translations.addFlats.cityView},
                    {value: "На море", label: translations.addFlats.seaView},
                    {value: "На горы", label: translations.addFlats.mountainView},
                    {value: "Первая линия у моря", label: translations.addFlats.firstLineSea},
                ].map((option) => (
                    <div key={option.value}>
                        <input
                            type="checkbox"
                            id={option.value}
                            value={option.value}
                            checked={view.includes(option.value)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setView([...view, option.value]); // Добавляем в массив
                                } else {
                                    setView(view.filter((v) => v !== option.value)); // Убираем из массива
                                }
                            }}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                    </div>
                ))}
            </div>


            <div className="input-container-checkbox">

                <label>
                    <label className="name-add-text">{translations.addFlats.elevator}</label>
                    <input type="checkbox"
                           checked={hasElevator}
                           onChange={(e) => setHasElevator(e.target.checked)}
                    />
                </label>
                <label>
                    <label className="name-add-text">{translations.addFlats.gas}</label>
                    <input type="checkbox"
                           checked={hasGas}
                           onChange={(e) => setHasGas(e.target.checked)}
                    />
                </label>
            </div>

            <label className="name-add-text">{translations.addFlats.heating}</label>
            <div>
                <label>
                    <input
                        type="checkbox"
                        value="CentralHeating"
                        checked={heatingOptions.includes("CentralHeating")}
                        onChange={(e) => handleHeatingChange(e)}
                    />
                    {translations.addFlats.centralHeating}
                </label>
                <label>
                    <input
                        type="checkbox"
                        value="AirConditioning"
                        checked={heatingOptions.includes("AirConditioning")}
                        onChange={(e) => handleHeatingChange(e)}
                    />
                    {translations.addFlats.airConditioning}
                </label>
                <label>
                    <input
                        type="checkbox"
                        value="ElectricHeating"
                        checked={heatingOptions.includes("ElectricHeating")}
                        onChange={(e) => handleHeatingChange(e)}
                    />
                    {translations.addFlats.electricHeating}
                </label>
                <label>
                    <input
                        type="checkbox"
                        value="UnderfloorHeating"
                        checked={heatingOptions.includes("UnderfloorHeating")}
                        onChange={(e) => handleHeatingChange(e)}
                    />
                    {translations.addFlats.underfloorHeating}
                </label>
                <label>
                    <input
                        type="checkbox"
                        value="Stove"
                        checked={heatingOptions.includes("Stove")}
                        onChange={(e) => handleHeatingChange(e)}
                    />
                    {translations.addFlats.stove}
                </label>
            </div>
            <label htmlFor="priceInUSD" className="name-add-text">{translations.addCars.priceInUSD}</label>

            <label htmlFor="price1day" className="name-add-text">{translations.addCars.pricePer1day}</label>

            <input
                type="text"
                placeholder={translations.addCars.pricePer1day}
                value={pricePerDay}
                onChange={(e) => setPricePerDay(e.target.value)}
            />

            <label htmlFor="price3days" className="name-add-text">{translations.addFlats.price3Days}:</label>
            <input
                type="text"
                placeholder={translations.addFlats.price3Days}
                value={pricePer3Days}
                onChange={(e) => setPricePer3Days(e.target.value)}
            />

            <label htmlFor="price7days" className="name-add-text">{translations.addFlats.priceWeek}:</label>
            <input
                type="text"
                placeholder={translations.addFlats.priceWeek}
                value={pricePerWeek}
                onChange={(e) => setPricePerWeek(e.target.value)}
            />

            <label htmlFor="price14days" className="name-add-text">{translations.addFlats.price14Days}:</label>
            <input
                type="text"
                placeholder={translations.addFlats.price14Days}
                value={pricePer14Days}
                onChange={(e) => setPricePer14Days(e.target.value)}
            />

            <label htmlFor="priceMonth" className="name-add-text">{translations.addFlats.priceMonth}:</label>
            <input
                type="text"
                placeholder={translations.addFlats.priceMonth}
                value={pricePerMonth}
                onChange={(e) => setPricePerMonth(e.target.value)}
            />

            <label htmlFor="priceForSelling" className="name-add-text">{translations.addFlats.priceForSelling}:</label>
            <input
                type="text"
                placeholder={translations.addFlats.priceForSelling}
                value={priceForSelling}
                onChange={(e) => setPriceForSelling(e.target.value)}
            />


            <ImageUpload
                onSingleImageChange={setSingleImage}
                onMultipleImagesChange={(files) => setMultipleImages(Array.from(files))}
            />
            <AddMap onMapDataChange={setMapCoordinates}/>
            <button onClick={handleSubmit} disabled={loading}>
                {loading ? (translations.addFlats?.loading || "Loading...") : (translations.addFlats?.submit || "Submit")}
            </button>
        </div>
    );
};

export default AddFlats;
