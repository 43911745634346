import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './SupportForm.css';
import supportIcon from "../../img/support.png";
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const SupportForm = () => {
    const [selectedOption, setSelectedOption] = useState('problem');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [files, setFiles] = useState([]);
    const [messengerInfo, setMessengerInfo] = useState('');
    const { translations } = useContext(LocalizationContext);
    const [selectedMessenger, setSelectedMessenger] = useState('');  // Новое состояние для выбранного мессенджера
    const navigate = useNavigate();

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleMessengerInfoChange = (e) => {
        setMessengerInfo(e.target.value);  // Обработчик для ввода данных мессенджера
    };

    const handleMessengerChange = (e) => {
        setSelectedMessenger(e.target.value);  // Обработчик для выбора мессенджера
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        const formData = new FormData();
        formData.append('message', message);
        files.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
        });

        try {
            const chatId = '-1002135710194'; // Ваш chat_id
            const botToken = '7356584757:AAFMITZXblh8k-FsOJdUK4yr62sUmAxG4gw'; // Ваш токен бота
            const telegramUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

            // Отправка сообщения в Telegram
            await fetch(telegramUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    chat_id: chatId,
                    text: `Support: ${message}\nMessenger: ${selectedMessenger}\nMessenger Info: ${messengerInfo}`, // Добавляем информацию о мессенджере
                }),
            });

            // Отправка файлов в Telegram (если есть)
            if (files.length > 0) {
                const sendDocumentUrl = `https://api.telegram.org/bot${botToken}/sendDocument`;
                for (let file of files) {
                    const fileFormData = new FormData();
                    fileFormData.append('chat_id', chatId);
                    fileFormData.append('document', file);

                    await fetch(sendDocumentUrl, {
                        method: 'POST',
                        body: fileFormData,
                    });
                }
            }

            console.log('Support Request Sent:', { selectedOption, message, files, selectedMessenger, messengerInfo });

            // Показываем уведомление на 5 секунд
            setNotificationVisible(true);
            setTimeout(() => {
                setNotificationVisible(false);
            }, 5000);
        } catch (error) {
            console.error('Ошибка при отправке данных в Telegram:', error);
        }

        setMessage('');
        setFiles([]);
        setMessengerInfo('');  // Очистка данных мессенджера
        setSelectedMessenger('');  // Очистка выбранного мессенджера
        setFormSubmitted(false);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

        const profileTranslations = translations?.profilePage || {};

    return (
        <div className="support-container">
            {/*<div className="support-image-container">*/}
            {/*    <img alt="Support Illustration" className="support-image" src={supportIcon}/>*/}
            {/*</div>*/}
            <div className="support-form-container">
                <button className="back-support-button" onClick={handleBackClick}>
                    <span>{translations.supportPage.backSupportButton}</span>
                </button>
                <div className="support-title-container">
                    <h1 className="support-title">{translations.profilePage.support}</h1>

                </div>
                <form onSubmit={handleFormSubmit} className="support-form">
                    <div className="support-options">
                        <label>
                            <input
                                type="radio"
                                value="problem"
                                checked={selectedOption === 'problem'}
                                onChange={handleOptionChange}
                            />
                            <span>{translations.supportPage.reportProblem}</span>

                        </label>
                        <label>
                            <input
                                type="radio"
                                value="telegram"
                                checked={selectedOption === 'telegram'}
                                onChange={handleOptionChange}
                            />
                            <span>{translations.supportPage.sendVia}</span>

                        </label>
                    </div>

                    {selectedOption === 'problem' && (
                        <div className="support-textarea-container">
                            <textarea
                                className="support-textarea"
                                placeholder={translations.supportPage.describeProblem}

                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                            <label className="support-file-label">
                                <input
                                    type="file"
                                    className="support-file-input"
                                    multiple
                                    onChange={handleFileChange}
                                />
                                <span className="support-file-icon">📎{files.length > 0 &&
                                    <span className="file-count">({files.length})</span>}</span>
                            </label>

                            {/* Поле для выбора мессенджера с точками выбора */}
                            <div className="messenger-selection-container">
                                <label>{translations.supportPage.messengerForContact}</label>
                                <select value={selectedMessenger} onChange={handleMessengerChange} required>
                                    <option value="">{translations.supportPage.chooseMessenger}</option>
                                    <option value="Telegram">Telegram</option>
                                    <option value="WhatsApp">WhatsApp</option>
                                    <option value="Viber">Viber</option>
                                    <option value="Instagram">Instagram</option>
                                </select>
                            </div>

                            {/* Поле для ввода логина или номера телефона */}
                            <div className="messenger-info-container">
                                <label>{translations.supportPage.inputLogin}</label>

                                <input
                                    type="text"
                                    value={messengerInfo}
                                    onChange={handleMessengerInfoChange}
                                    required
                                    placeholder={translations.supportPage.phoneNumberLogin}

                                />
                            </div>
                        </div>
                    )}

                    {selectedOption === 'telegram' && (
                        <p className="support-instructions">
                            Свяжитесь с нами через один из мессенджеров:
                            <a href="https://t.me/weeekendteam" target="_blank"
                               rel="noopener noreferrer">Telegram</a>,{' '}
                            <a href="https://wa.me/+90 536 654 10 83" target="_blank"
                               rel="noopener noreferrer">WhatsApp</a>,{' '}
                            <a href="viber://chat?number=your_number" target="_blank"
                               rel="noopener noreferrer">Viber</a>.
                        </p>
                    )}

                    <button type="submit" className="support-submit-button">
                        <span>{translations.supportPage.send}</span>
                    </button>

                    {notificationVisible && (
                        <div className="push-notification">
                            Ваш запрос успешно отправлен!
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SupportForm;
