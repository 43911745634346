import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MAPBOX_ACCESS_TOKEN } from '../../config';

const useMap = (mapContainerRef, geocoderContainerRef, icon, mapStyle) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const add3DBuildingsLayer = (map) => {
        if (!map.isStyleLoaded()) {
            console.warn('Стиль карты не загружен, пропуск добавления 3D-слоев');
            return;
        }

        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
            (layer) => layer.type === 'symbol' && layer.layout['text-field']
        )?.id;

        if (labelLayerId) {
            console.log('Adding 3D buildings layer');
            map.addLayer({
                id: 'add-3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                type: 'fill-extrusion',
                paint: {
                    'fill-extrusion-height': ['get', 'height'],
                    'fill-extrusion-base': ['get', 'min_height'],
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-opacity': 0.6,
                },
            }, labelLayerId);
        }
    };

    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle,
            center: [41.62, 41.64],
            zoom: 12,
            pitch: 45,
            bearing: 90.6,
            antialias: true,
        });

        mapRef.current = map;
        map.addControl(new mapboxgl.NavigationControl());

        // Слушаем событие 'style.load'
        map.on('style.load', () => {
            console.log('Style loaded');
            add3DBuildingsLayer(map);
        });

        // Добавляем геокодер
        // Добавляем геокодер
        new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Search for a location...',
            marker: true,
            container: geocoderContainerRef.current,
        });

        return () => {
            if (map) map.remove(); // Удаляем карту при размонтировании
        };
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            map.setStyle(mapStyle);

            map.once('style.load', () => {
                console.log('Style switched to:', mapStyle);
                add3DBuildingsLayer(map);
            });

            map.on('error', (e) => {
                console.error('Error loading new style:', e);
            });
        }
    }, [mapStyle]);

    return { mapRef, markerRef };
};

export default useMap;
