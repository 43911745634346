import React, { useState, useContext } from "react";
import { LocalizationContext } from "../../Services/localization/LocalizationContext";

function ImageUpload({ onSingleImageChange, onMultipleImagesChange }) {
    const { translations } = useContext(LocalizationContext);
    const [singleImagePreview, setSingleImagePreview] = useState(null);
    const [multipleImagesPreviews, setMultipleImagesPreviews] = useState([]);

    // Функция для сжатия изображения
    const handleImageResize = (file, maxSizeKB = 200) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.onload = () => {
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');
                    let maxWidth = 1024; // Максимальная ширина изображения
                    let maxHeight = 1024; // Максимальная высота изображения

                    // Пропорциональное изменение размера
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    // Преобразуем canvas в base64
                    canvas.toBlob((blob) => {
                        // Проверка размера файла и сжатие до максимума
                        if (blob.size / 1024 <= maxSizeKB) {
                            const newFile = new File([blob], file.name, {
                                type: file.type,
                            });
                            resolve(newFile);
                        } else {
                            // Попытаться уменьшить размер, если оно превышает лимит
                            const quality = 0.8; // Начальное качество
                            canvas.toBlob((resizedBlob) => {
                                const newFile = new File([resizedBlob], file.name, {
                                    type: file.type,
                                });
                                if (resizedBlob.size / 1024 <= maxSizeKB) {
                                    resolve(newFile);
                                } else {
                                    reject(new Error("Unable to resize image within the size limit"));
                                }
                            }, file.type, quality);
                        }
                    }, file.type);
                };
                img.src = e.target.result;
            };

            reader.readAsDataURL(file);
        });
    };

    const handleSingleImageChange = async (file) => {
        if (file) {
            try {
                const resizedImage = await handleImageResize(file); // Сжимаем изображение
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSingleImagePreview(reader.result);
                };
                reader.readAsDataURL(resizedImage);
                onSingleImageChange(resizedImage); // Отправляем сжатоe изображение
            } catch (error) {
                console.error("Error resizing image:", error);
                // Если ошибка при сжатии, просто загружаем изображение без изменений
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSingleImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
                onSingleImageChange(file);
            }
        }
    };

    const handleMultipleImagesChange = async (files) => {
        const resizedImages = [];
        for (let file of files) {
            try {
                const resizedImage = await handleImageResize(file); // Сжимаем изображение
                resizedImages.push(resizedImage);
            } catch (error) {
                console.error("Error resizing image:", error);
                resizedImages.push(file); // Если ошибка, добавляем оригинальное изображение
            }
        }

        // Отображаем все превью
        const previews = await Promise.all(
            resizedImages.map((image) =>
                new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(image);
                })
            )
        );

        setMultipleImagesPreviews(previews);
        onMultipleImagesChange(resizedImages); // Отправляем сжатоe изображение
    };

    return (
        <div className="disFlex centring">
            <div>
                <label>{translations.imageUpload.uploadMainPhoto}</label>
                <input
                    type="file"
                    onChange={(e) => handleSingleImageChange(e.target.files[0])}
                />
                {singleImagePreview && (
                    <div>
                        <p>{translations.imageUpload.singleImagePreview}</p>
                        <img
                            src={singleImagePreview}
                            alt="Single Preview"
                            style={{ width: "50px", height: "auto" }}
                        />
                    </div>
                )}
            </div>

            <div>
                <label>{translations.imageUpload.uploadOtherPhotos}</label>
                <input
                    type="file"
                    multiple
                    onChange={(e) => handleMultipleImagesChange(e.target.files)}
                />
                {multipleImagesPreviews.length > 0 && (
                    <div>
                        <p>{translations.imageUpload.multipleImagesPreview}</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                            {multipleImagesPreviews.map((preview, index) => (
                                <img
                                    key={index}
                                    src={preview}
                                    alt={`Preview ${index + 1}`}
                                    style={{ width: "100px", height: "auto" }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImageUpload;
