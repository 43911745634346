import React, { useRef, useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './map.css';
import './animatedPopup.css';
import icon from '../img/Point.svg';
import CategoryBarMap from '../CategoryBar/CategoryBarMap/CategoryBarMap';
import { CategoryContext } from '../../App';
import useMap from '../hooks/useMap';
import usePopups from '../hooks/usePopups';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import CarCard from '../Cards/CarCard/CarCard';
import FlatCard from '../Cards/FlatCard/FlatCard';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';


import {title} from "framer-motion/m";

const Map = () => {
    const { translations } = useContext(LocalizationContext);
    const mapContainerRef = useRef(null);
    const geocoderContainerRef = useRef(null);
    const { activeCategories, setActiveCategories } = useContext(CategoryContext);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'day-mode');
    const markersRef = useRef([]);
    const [zoomLevel, setZoomLevel] = useState(12);
    const mapStyle =
        theme === 'night-mode'
            ? 'mapbox://styles/weeefamily/cm5jft998002r01s70tv9bq50'
            : 'mapbox://styles/weeefamily/cm5ikdy0c001x01s74x38fj18';
    const [is3D, setIs3D] = useState(true);
    // Using the custom hooks
    const { mapRef, markerRef } = useMap(mapContainerRef, geocoderContainerRef, icon, mapStyle);
    const popups = usePopups();
    const [markersReady, setMarkersReady] = useState(false);
    const [areMarkersLoaded, setAreMarkersLoaded] = useState(false);
    useEffect(() => {
        if (markersReady && markersRef.current.length > 0) {
            setAreMarkersLoaded(true); // Устанавливаем, что маркеры загружены
        }
    }, [markersReady, markersRef.current.length]);


    useEffect(() => {
        if (mapRef.current) {
            const initialPitch = mapRef.current.getPitch();
            setIs3D(initialPitch > 0); // Set to 3D if the initial pitch is greater than 0
        }
    }, [mapRef]);

    useEffect(() => {
        const handleThemeChange = (event) => {
            setTheme(event.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);

        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    // Update the zoom level and adjust marker text visibility
const handleZoom = () => {
    const map = mapRef.current;
    const currentZoom = map.getZoom();
    setZoomLevel(currentZoom);

    // Обновляем видимость 'marker-title' в зависимости от уровня зума
    markersRef.current.forEach(marker => {
        const markerElement = marker.getElement();
        const title = markerElement.querySelector('.marker-title');

        if (title) {
            // Скрываем title на зуме ниже 12
            if (currentZoom < 12) {
                title.style.display = 'none';
            } else {  // Показываем title при зуме 12 и выше
                title.style.display = 'block';
            }
        }

        const subtitle = markerElement.querySelector('.marker-subtitle');
        if (subtitle) {
            // Скрываем subtitle на зуме ниже 12
            if (currentZoom < 12) {
                subtitle.style.display = 'none';
            } else {
                subtitle.style.display = 'block';
            }
        }
    });
};

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on('zoom', handleZoom); // Attach zoom event listener
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoom', handleZoom); // Clean up zoom event listener
            }
        };
    }, [mapRef]);


useEffect(() => {
    console.log('Rendering popups', popups);

    // Удаляем старые маркеры
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    if (mapRef.current) {
        popups.forEach((popup, index) => {
            const popupNode = document.createElement('div');
            popupNode.className = 'popup-content';
            const cardComponent = (
                <>
                    {activeCategories.includes("cars") && <CarCard car={popup} />}
                    {activeCategories.includes("flats") && <FlatCard flat={popup} />}
                </>
            );
            ReactDOM.createRoot(popupNode).render(cardComponent);

            const mapPopup = new mapboxgl.Popup({
                offset: 25,
                closeButton: false,
                closeOnClick: true,
                className: 'custom-popup'
            }).setDOMContent(popupNode);

            // Создание маркера
            const markerElement = document.createElement('div');
            markerElement.className = 'custom-marker';

            if (activeCategories.includes("cars")) {
                markerElement.innerHTML = `
                    <div class="marker-content">
                        <div class="marker-text">
                            <span class="marker-subtitle">${popup.model} ${popup.volume}</span>
                            <span class="marker-title">${(Math.random() * 0.3 + 4.7).toFixed(1)}</span> <!-- Сюда добавляем заголовок -->
                        </div>
                    </div>
                `;
            } else if (activeCategories.includes("flats")) {
                markerElement.innerHTML = `
                    <div class="marker-content">
                        <div class="marker-text">
                            <span class="marker-subtitle">${popup.propertyType} ${popup.dealType} </span>
                            <span class="marker-title">${(Math.random() * 0.3 + 4.7).toFixed(1)}</span> <!-- Сюда добавляем заголовок -->
                        </div>
                    </div>
                `;
            }
            markerElement.style.backgroundImage = `url(${popup.imageUrl})`;

            const marker = new mapboxgl.Marker({ element: markerElement })
                .setLngLat(popup.location)
                .setPopup(mapPopup)
                .addTo(mapRef.current);

            markerElement.addEventListener('click', (e) => {
                e.stopPropagation();
                if (mapPopup.isOpen()) {
                    mapPopup.remove();
                } else {
                    mapPopup.addTo(mapRef.current);
                }
            });

            markersRef.current.push(marker);
        });

        mapRef.current.on('click', (e) => {
            if (e.target instanceof HTMLElement) {
                const isPopupClicked = e.target.closest('.custom-popup');
                if (!isPopupClicked) {
                    markersRef.current.forEach(marker => {
                        const popup = marker.getPopup();
                        if (popup.isOpen()) {
                            popup.remove();
                        }
                    });
                }
            }
        });
    }
}, [popups, mapRef]);


    useEffect(() => {
        if (mapRef.current && !geocoderContainerRef.current.geocoder) {
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
                placeholder: translations.map.enterAddress,
            });
            geocoderContainerRef.current.geocoder = geocoder;
            geocoder.addTo(geocoderContainerRef.current);

            geocoder.on('result', (event) => {
                const coordinates = event.result.geometry.coordinates;
                if (markerRef.current) {
                    markerRef.current.setLngLat(coordinates);
                } else {
                    markerRef.current = new mapboxgl.Marker().setLngLat(coordinates).addTo(mapRef.current);
                }
                mapRef.current.flyTo({
                    center: coordinates,
                    zoom: 15,
                });
            });
        }
    }, [mapRef]);

    const togglePitch = () => {
        const map = mapRef.current;
        const currentPitch = map.getPitch();
        const tg3dElement = document.getElementById('tg3d');

        if (currentPitch === 0) {
            map.easeTo({
                pitch: 60,
                duration: 500,
            });
            tg3dElement.classList.add('active');
            setIs3D(true);
        } else {
            map.easeTo({
                pitch: 0,
                duration: 500,
            });
            tg3dElement.classList.remove('active');
            setIs3D(false);
        }
    };


    const centerOnUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
        (position) => {
            const { latitude, longitude } = position.coords;
            const map = mapRef.current;

            // Центрируем карту на координатах пользователя
            map.flyTo({
                center: [longitude, latitude],
                zoom: 15,
                speed: 1.2,
                curve: 1,
                essential: true,
            });

            // Если маркер уже существует, обновляем его координаты
            if (markerRef.current) {
                markerRef.current.setLngLat([longitude, latitude]);
            } else {
                // Создаем кастомный элемент маркера
                const customIcon = document.createElement('div');
                customIcon.className = 'custom-marker';
                customIcon.style.backgroundImage = `url(${icon})`; // Используем backgroundImage вместо innerHTML
                customIcon.style.width = '40px';
                customIcon.style.height = '40px';
                customIcon.style.backgroundSize = 'contain';

                // Создаем маркер
                const marker = new mapboxgl.Marker({ element: customIcon })
                    .setLngLat([longitude, latitude])
                    .addTo(map);

                markerRef.current = marker;
            }
        },
        (error) => {
            console.error('Error getting location:', error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
};
     useEffect(() => {
        const curPopupId = localStorage.getItem("currentPopupId");

        console.log("Список попапов:", popups);
        console.log("Текущий popupId из localStorage:", curPopupId);

        if (!curPopupId) {
            console.warn("popupId отсутствует в localStorage.");
            return;
        }

        // Поиск попапа в массиве popups
        const targetPopup = popups.find(popup => popup.id === curPopupId);

        if (targetPopup) {
            console.log("Найден попап с ID:", curPopupId);

            // Создаем DOM-элемент для попапа
            const popupNode = document.createElement('div');
            popupNode.setAttribute('popupId', targetPopup.id);
            popupNode.className = 'popup-content';

            // Рендерим соответствующий компонент для попапа
            const cardComponent = (
                <>
                    {activeCategories.includes("cars") && <CarCard car={targetPopup} />}
                    {activeCategories.includes("flats") && <FlatCard flat={targetPopup} />}
                </>
            );
            ReactDOM.createRoot(popupNode).render(cardComponent);

            // Создаем Mapbox Popup
            const mapPopup = new mapboxgl.Popup({
                offset: 25,
                closeButton: false,
                closeOnClick: true,
                className: 'custom-popup'
            }).setDOMContent(popupNode);

            // Добавляем попап на карту
            mapPopup.setLngLat(targetPopup.location).addTo(mapRef.current);
        } else {
            console.warn("Попап с указанным popupId не найден в массиве popups:", curPopupId);
        }
    }, [areMarkersLoaded, popups, mapRef, activeCategories]);

    return (
        <div className="map_container">
            <div className="search-and-gif-container">
                <div ref={geocoderContainerRef} className="geocoder-container"/>
            </div>
            <div className={`toggle3d fas ${is3D ? 'fa-cube' : 'fa-map'}`} id="tg3d" onClick={togglePitch}>
                <p>{is3D ? "3D" : "2D"}</p>
            </div>
            <div className="locate-button" onClick={centerOnUserLocation}>📍</div>
            <CategoryBarMap onCategoryChange={setActiveCategories} isMapView={true}/>
            <div ref={mapContainerRef} id="geocoder-container"/>
        </div>
    );
};

export default Map;