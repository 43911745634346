import { useEffect, useState, useContext } from 'react';
import { db, collection, getDocs } from '../../firebase';
import { CategoryContext } from '../../App';

const usePopups = () => {
    const { activeCategories } = useContext(CategoryContext);
    const [popups, setPopups] = useState([]);


    useEffect(() => {
        if (!activeCategories || activeCategories.length === 0) {

            setPopups([]);
            return;
        }

        let isMounted = true;

        const fetchPopups = async () => {
            try {

                let popupData = [];
                for (const item of activeCategories) {
                    const querySnapshot = await getDocs(collection(db, item));

                    const responseData = querySnapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            categoryType: item,
                            ...doc.data()
                        }))
                        .filter(categoryItem => {
                            const location = categoryItem.location || {};
                            return categoryItem.status === 'approved' && (location.lng && location.lat);
                        });

                    popupData = [...popupData, ...responseData];

                }



                if (isMounted) {
                    setPopups((prevPopups) => {
                        if (JSON.stringify(prevPopups) === JSON.stringify(popupData)) {

                            return prevPopups;
                        }
                        return popupData;
                    });
                }
            } catch (error) {
                if (isMounted) {

                }
            }
        };

        fetchPopups();

        return () => {
            isMounted = false;
        };
    }, [activeCategories]);

    return popups;
};

export default usePopups;
